import React from "react";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router";
import { renderTabPanel } from "./Settings/tabs";

function Settings({ t }) {
    let { type } = useParams();

    return (<>
        {renderTabPanel(type)}
    </>
    )



}

export default withTranslation()(Settings);