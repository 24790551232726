import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router";
import Center1Column from "../../../layout/Page/Center/1column";
import { CheckIcon, XCircleIcon, XIcon } from '@heroicons/react/solid'
import classNames from "classnames";
import Card from "../../../components/Card/Card";
import { CheckUnicityConflict, GenerateLightForms, formData, getCurentUser, tenantData, tenantLongData, updateFormStatus, updateHubspotSkeletonById, } from "../../../utils/requests/cosmo";
import ChoiceModal from "../../../components/Popup/choiceModal";

const steps = [
    {
        id: 1,
        content: "Checking deployment settings",
        errors: []
    },
    {
        id: 2,
        content: "Checking application information",
        errors: []
    },
    {
        id: 3,
        content: "Checking the usage of all selected data",
        errors: []
    },
    {
        id: 4,
        content: "Checking the unicity of all unique parameters",
        errors: []
    },
    {
        id: 5,
        content: "Checking for hubspot schema conflicts",
        errors: []
    }
]

const tenantDataToCheck = [
    {
        name: "hubspot",
        sub: [
            "keyFO",
            "schema",
            "pipelines"
        ]
    },
    {
        name: "deployment",
        sub: [
            "client_ID",
            "secret_ID",
            "resource",
            "authority"
        ]
    },
]

function Validation({ t }) {
    const [loading, setLoading] = useState(true)
    const [candidature, setCandidature] = useState(null);
    const [tenant, setTenant] = useState(null);
    const [userRights, setUserRights] = useState({})
    const [autofix, setAutofix] = useState(false)

    let { id } = useParams();

    useEffect(() => {
        // tenantData().then(tenantdata => {
        //     // console.log(tenantdata)
        //     let localDeploy = [];
        //     setTenant(tenantdata);
        //     tenantDataToCheck.forEach((d) => {
        //         d.sub.forEach((sub) => {
        //             // console.log(tenantdata[d.name][sub])
        //             if (tenantdata[d.name][sub] === "" || tenantdata[d.name][sub] === null) {
        //                 // console.log(sub + " missing")
        //                 localDeploy.push(sub + " missing")
        //             }
        //         })
        //     })
        //     steps[0].errors = localDeploy;
        // })
    }, [])

    useEffect(() => {
        setLoading(true);
        tenantData().then(tenantD => {
            CheckUnicityConflict(id).then(conflictList => {
                checkUnicity(conflictList, tenantD.forms)
            })
        })
        getCurentUser().then(curentUser => {
            setUserRights(curentUser.rights)
            if (tenant === null) {
                tenantLongData().then(tenantdata => {
                    checkMissingDeployment(tenantdata)
                })
            } else {
                checkMissingDeployment(tenant)
            }
            formData(id).then(candidature => {
                setCandidature(candidature);
                checkUnusedData(candidature);
                checkInformations(candidature);
                checkHbErrors(candidature)
                setLoading(false);
            })
        })
    }, [id]);

    function checkMissingDeployment(tenantdata) {
        setLoading(true);
        let localDeploy = [];
        setTenant(tenantdata);
        tenantDataToCheck.forEach((d) => {
            d.sub.forEach((sub) => {
                if (tenantdata[d.name][sub] === "" || tenantdata[d.name][sub] === null) {
                    localDeploy.push(sub + " missing")
                }
            })
        })
        steps[0].errors = localDeploy;
        setLoading(false);
    }

    function checkUnusedData(candidature) {
        let localNames = []
        let unusedLocal = []
        candidature.Step.forEach(step => {
            step.questions.forEach(question => {
                if (question.name !== null) {
                    localNames.push(question.name)
                }
            })
        })
        candidature.Data.forEach(data => {
            if (!localNames.includes(data.name)) {
                unusedLocal.push(data.label + " not used")
            }
        })
        steps[2].errors = unusedLocal;
    }

    function checkInformations(candidature) {
        let localErrors = []
        Object.keys(candidature.Parameters).forEach(k => {
            if (candidature.Parameters[k] === "") {
                localErrors.push(k + " not given")
            }
        })
        Object.keys(candidature.Pipeline).forEach(k => {
            if (candidature.Pipeline[k] === "") {
                localErrors.push("Pipeline " + k + " not given")
            }
        })
        steps[1].errors = localErrors;
    }

    function checkUnicity(conflictList, forms) {
        let localErrors = []
        forms.forEach(form => {
            if (conflictList.includes(form.id)) {
                localErrors.push("Conflict with " + form.Parameters[0].value + " (" + form.id + ")")
            }
        });
        steps[3].errors = localErrors
    }

    function checkHbErrors(candidature) {
        let localErrors = []
        let questions = 0
        let conditional = 0
        let option = 0
        if (candidature.ErrorsId) {
            candidature.ErrorsId.forEach(err => {
                switch (err.split(':').pop())
                {
                    case "question":
                        questions++;
                        break
                    case "conditional":
                        conditional++;
                        break
                    case "option":
                        option++;
                        break
                    default:
                        break
                }
            });
            if (questions) {
                localErrors.push(t("There are ") + questions + t(" errors with questions that don't exist anymore"))
            }
            if (conditional) {
                localErrors.push(t("There are ") + conditional + t(" errors with conditions broken"))
            }
            if (option) {
                localErrors.push(t("There are ") + option + t(" errors with custom options broken"))
            }
            steps[4].errors = localErrors
        }
    }

    function enableButton() {
        const cand = JSON.parse(JSON.stringify(candidature));
        if (cand) {
            if (cand.enabled === true) {
                cand.enabled = false;
            } else if (steps[0].errors.length === 0 && steps[1].errors.length === 0 && steps[2].errors.length === 0) {
                cand.enabled = true;
            }
            // console.log(JSON.parse(JSON.stringify(cand)))
            if (cand.enabled !== candidature.enabled) {
                setLoading(true)
                updateFormStatus(cand).then(statu => {
                    candidature.enabled = cand.enabled;
                    setLoading(false)
                    GenerateLightForms()
                })
            }
            // updateCandidature(candidature).then(res => { if (res.ok) { return res.json() } }).then(cand => {
            //     setCandidature(cand);
            // });
        }
    }

    function autofixFunc() {
        setLoading(true);
        updateHubspotSkeletonById(id).then(() => {
            GenerateLightForms().then(() => {
                formData(id).then(candidature => {
                    checkHbErrors(candidature)
                    setLoading(false);
                })
            })
        })
    }

    return <Center1Column>
        <Card>
            <div className="flex justify-between">
                <h2 className="text-left text-base font-medium text-gray-900 text-2xl">{t("Validation du formulaire")}</h2>
            </div>
            <p className=" pt-2 text-gray-500 text-light text-sm">{t("Cette page est le récapitultatif de toutes les données que vous avez rempli pour la création de votre formulaire ainsi que la vérification des étapes nécessaires pour le déploiement du formulaire en production. Une fois tous les champs valide, vous pouvez activer en haut le formulaire et redéployer l'application depuis le menu principal.")}</p>

            <div className="flow-root mt-8">
                <ul className="-mb-8">
                    {steps.map((step, eventIdx) => (
                        <li key={step.id}>
                            <div className="relative pb-8">
                                {eventIdx !== steps.length - 1 ? (
                                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                ) : null}
                                <div className="relative flex space-x-3">
                                    <div>
                                        <span
                                            className={classNames(
                                                step?.errors?.length ? 'bg-red-500' : 'bg-green-500',
                                                'h-8 w-8 rounded-full flex items-center justify-center'
                                            )}
                                        >
                                            {step?.errors?.length ? <XIcon className="h-5 w-5 text-white" aria-hidden="true" /> : <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />}
                                        </span>
                                    </div>
                                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1">
                                        <div>
                                            <p className="text-md text-gray-700 font-medium">
                                                {t(step.content)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {step?.errors?.length ? <div className="ml-8 mt-2">
                                    <div className="rounded-md bg-red-50 p-4">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="text-sm font-medium text-red-800">{t("x missing elements", { x: step.errors.length })}</h3>
                                                <div className="mt-2 text-sm text-red-700">
                                                    <ul className="list-disc space-y-1 pl-5">
                                                        {step.errors.map((error, i) => <li key={i}>{error}</li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex justify-center mt-8">
                {steps[4].errors.length > 0 && <button onClick={() => setAutofix(true)} disabled={loading || !userRights.data} className={"mx-5 bg-red-600 focus:ring-red-500 enabled:hover:bg-red-700 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer disabled:opacity-50" + (loading ? " cursor-not-allowed opacity-50" : "")}>{t("Autofix conflicts")}</button>}
                <button onClick={enableButton} disabled={loading || !userRights.activate_form} className={classNames(candidature?.enabled ? "bg-red-600 focus:ring-red-500 enabled:hover:bg-red-700" : "bg-blue-600 focus:ring-blue-500 enabled:hover:bg-blue-700", "mx-5 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer disabled:opacity-50") + (loading ? " cursor-not-allowed opacity-50" : "")}>{candidature?.enabled ? t("Deactivate") : t("Activate")}</button>
            </div>
        </Card>
        {autofix && <ChoiceModal title={"Are you sure ?"} detail={"This action will remove some data from this form, if this ok for you press Accept"} cancel={() => setAutofix(false)} accept={() => { setAutofix(false); autofixFunc() }}></ChoiceModal>}
    </Center1Column>

}

export default withTranslation()(Validation);