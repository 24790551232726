import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useEffect, useState } from "react";
// import shortid from "shortid";
import { v4 as uuidv4 } from 'uuid';
import "./accordion.css"

function CollapseAccordion({ visual, contentVisual, items }) {
    const [index, setIndex] = useState(null);
    const [id, setId] = useState();

    useEffect(() => {
        setId(uuidv4())
        if (items && items.length === 1) {
            setIndex(0);
        }
    }, [items])

    return items && items.length ?
        <div className=" bg-white rounded-lg overflow-y-auto relative h-full" style={{ maxHeight: "inherit" }}>
            <div className="accordion h-full w-full" style={{ maxHeight: "inherit" }} id={"accordion-collapse-" + id}>
                {items.map((item, i) =>
                    <div key={uuidv4()} className="accordion-item h-full w-full">
                        <div className={classNames(index === i ? "sticky top-0" : "", i === 0 ? "" : "border-t", "accordion-header w-full px-4 py-3")} id={"header-" + i} >
                            <button className="cursor-pointer w-full flex justify-between relative transition items-center" type="button" data-bs-toggle="collapse" data-bs-target={"#body-" + i} aria-expanded="true"
                                onClick={() => {
                                    setIndex(index === i ? null : i);
                                }}
                                aria-controls={i}>
                                <div>{visual(item, i)}</div>
                                <div>{i === index ? <ChevronDownIcon className="h-5 w-5" /> : <ChevronUpIcon className="h-5 w-5" />}
                                </div>
                            </button>
                        </div>
                        <div id={"body-" + i} className={index === i ? "accordion-collapse" : "accordion-collapse collapse hidden"}
                            aria-labelledby={"header-" + i} data-bs-parent={"#accordion-collapse-" + id}>
                            <div className="accordion-body  bg-gray-100  border-l border-r border-gray-200">
                                {contentVisual(item, i)}
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
        </div> : null
}

export default CollapseAccordion;