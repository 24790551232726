import React from "react";

export default class TextArea extends React.Component {
  render() {
    var { id, register, parameters, ...props } = this.props
    return register ? <textarea
      name={id}
      id={"textarea-" + id}
      type="text"
      rows="9"
      className="block w-full shadow-sm sm:text-sm border border-gray-300 p-4 focus:outline-none focus:ring-0 focus:border-primary"
      {...register(id, parameters)}
      {...props}
    /> : <textarea
      name={id}
      id={"textarea-" + id}
      type="text"
      rows="4"
      className="block w-full sm:text-sm border border-gray-100 p-4 focus:outline-none focus:ring-0 focus:border-primary"
      {...props}
    />
  }

}