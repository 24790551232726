import LoadingSpinner from "../Animation/LoadingSpinner";

export default function SaveButton({ loading, disabled, className, ...newProps }) {
    return (<button
        disabled={loading || disabled}
        className={disabled ? "inline-flex items-center border border-transparent rounded-md text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white opacity-50 cursor-not-allowed" :
            (className ? className :
                (loading ? "inline-flex items-center border border-transparent rounded-md text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 cursor-not-allowed" : "fill-primary-500 hover:fill-primary-700 inline-flex items-center border border-transparent rounded-md text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white hover: hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"))}
        {...newProps}
    >
        {loading ? <LoadingSpinner /> : <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="30px" height="30px" viewBox="0 0 300.000000 295.000000"
             preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.10, written by Peter Selinger 2001-2011
            </metadata>
            <g transform="translate(0.000000,295.000000) scale(0.100000,-0.100000)"
            stroke="black">
                <path d="M278 2762 c-61 -37 -58 35 -55 -1300 l2 -1222 33 -32 32 -33 1203 -3
                c888 -2 1209 1 1230 9 15 7 37 23 47 37 20 24 20 47 20 1256 0 1100 -2 1234
                -16 1254 -38 55 26 52 -1271 52 -1110 -1 -1200 -2 -1225 -18z m342 -680 l0
                -629 26 -24 26 -24 843 0 843 0 26 24 26 24 0 629 0 629 153 -3 152 -3 0
                -1230 0 -1230 -315 -3 -315 -2 3 327 c2 179 1 337 -3 350 -13 53 -18 53 -623
                53 -551 0 -560 0 -586 -21 l-26 -20 0 -345 0 -344 -268 0 c-195 0 -271 3 -280
                12 -17 17 -17 2429 0 2446 8 8 59 12 165 12 l153 0 0 -628z m1720 3 l0 -615
                -825 0 -825 0 0 615 0 615 825 0 825 0 0 -615z m-615 -1510 l0 -325 -402 0
                -403 0 0 325 0 325 403 0 402 0 0 -325z m295 0 l0 -325 -115 0 -115 0 0 325 0
                325 115 0 115 0 0 -325z"/>
                <path d="M1000 585 l0 -235 115 0 114 0 3 235 3 235 -117 0 -118 0 0 -235z
                m185 0 l0 -190 -67 -3 -68 -3 0 189 c0 104 3 192 7 196 4 4 34 6 68 4 l60 -3
                0 -190z"/>
            </g>
            </svg>}
    </button>)
}