import { useEffect, useRef, useState } from "react";

function Center1Column({ children, sideBar, onScroll, middleHeader, sideBarRight }) {
    const refPage = useRef(null);
    const [pageStyle, setPageStyle] = useState(null);

    useEffect(() => {
        setPageStyle("100%");
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (refPage && refPage.current && refPage.current.offsetTop) {
            let diff = window.innerHeight - refPage.current.offsetTop;
            setPageStyle(diff);
        }
    }, [refPage])

    // useEffect(() => {
    //     // à corriger, se répète en boucle
    //     if (onOffsetHeightChange && refPage && refPage.current && refPage.current.offsetHeight) {
    //         onOffsetHeightChange();
    //     }
    // }, [])

    function handleResize() {
        if (refPage && refPage.current && refPage.current.offsetHeight > 0) {
            setPageStyle((window.innerHeight - refPage.current.offsetTop));
        }
    }

    return <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <section>
            {sideBar ? (<div className="grid grid-cols-1 items-start lg:grid-cols-7 lg:gap-4" >
                {!sideBarRight && <div className="hidden lg:block col-span-2 overflow-hidden" style={{ maxHeight: pageStyle + 100, "height": middleHeader ? pageStyle - 40 : pageStyle + 50 }}>
                    {sideBar}
                </div>}
                <div className="col-span-5">
                    {middleHeader && <div className="pb-3">{middleHeader}</div>}
                    <div className="overflow-y-auto" style={{ "height": middleHeader ? pageStyle - 40 : pageStyle + 50 }} 
                    // onScroll={onScroll ? () => onScroll(refPage) : null}
                     onLoad={handleResize ? () => handleResize() : null} ref={refPage}>
                        {children}
                    </div>
                </div>
                {sideBarRight && <div className="col-span-2 overflow-hidden" style={{ maxHeight: pageStyle + 100 }}>
                    {sideBar}
                </div>}
            </div>) : <div className="overflow-y-auto" style={{ "height": pageStyle }} 
            // onScroll={onScroll ? () => onScroll(refPage) : null}
             onLoad={handleResize ? () => handleResize() : null} ref={refPage}>
                {children}
            </div>}
        </section>
    </div>
}

export default Center1Column;