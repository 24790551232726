import { withTranslation } from "react-i18next";
import React, { Fragment, Suspense, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Input from "../Inputs/Input";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import LoadingSpinner from "../Animation/LoadingSpinner";
import { defaultStyles, JsonView } from "react-json-view-lite";
import { toast } from "react-toastify";


function JsonPopup({ t, cancel, json, title }) {
    const [open, setOpen] = useState(true)
    const [localJson, setLocalJson] = useState({})

    useEffect(() => {
        if (json) {
            if (typeof json === "string") {
                setLocalJson(JSON.parse(json))
            } else {
                setLocalJson(JSON.parse(JSON.stringify(json)))
            }
        }
    }, [json]);

    const cancelButtonRef = useRef(null)

    useEffect(() => {
        if (open === false) {
            cancel()
        }
    }, [open, cancel])

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full max-w-screen-lg mx-16">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="">
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                {t(title)}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                            <React.Fragment>
                                                <JsonView data={localJson}
                                                style={defaultStyles}
                                                clickToExpandNode={true}
                                                shouldExpandNode={(level, value, field) => {
                                                    if (field !== "options") return true
                                                    return false
                                                }}
                                                // displayDataTypes={false}
                                                 />
                                            </React.Fragment>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:ml-6"
                                        onClick={() => {setOpen(false);cancel()}}
                                        ref={cancelButtonRef}
                                    >
                                        {t("Cancel")}
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-6 sm:w-auto"
                                        onClick={() => {navigator.clipboard.writeText(JSON.stringify(localJson));toast.success(t("Has been copied"))}}
                                    >
                                        {t("Copy")}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default withTranslation()(JsonPopup);