import React, { useRef, useState, useEffect, useMemo } from "react";
import { useDrag } from "react-dnd";
import { COMPONENT } from "./constants";
import { CogIcon, ExclamationIcon, InformationCircleIcon } from '@heroicons/react/outline';
import Select1 from "../Blank/Select";
import { t } from "i18next";
import URLLogo from "../../pages/Theme/Component/URLLogo";
import { Tooltip } from "react-tooltip";
import { uploadFile } from "../../utils/requests/cosmo";
import Select from "../Inputs/Select";
import Prix from "../Inputs/Prix";

const conditionalColor = ["border-indigo-500", "border-emerald-500", "border-yellow-500", "border-rose-500"]

const Component = ({ data, components, path, handleDetails, save, selectedItem, layout, setRefresh, refresh, selectedSubs, payment }) => {
  const ref = useRef(null);
  const [type, setType] = useState(null);
  const [notSave, setNotSave] = useState(false);
  const [shrink, setShrink] = useState(false);
  const [sub, setSub] = useState("");
  const [color, setColor] = useState("");
  const [selectedPosition, setSelectedPosition] = useState(-1)
  const [subDephs, setSubDephs] = useState(-1)
  const [ISO4217, setISO] = useState(null)
  // const [option, setOption] = useState([]);
  components[data.id].ref = ref;

  useEffect(() => {
    if (components) {
      if (components[data.id].data.fieldType) {
        setType(components[data.id].data.fieldType);
        // console.log(components[data.id].data.fieldType);
        if (components[data.id].data.sub) {
          setSub(components[data.id].data.sub)
          getSubDephs()
        } else {
          setSub("")
        }
        colorPick()
        if (components[data.id].content === "Paybox") {
          readISO().then(iso => setISO(iso))
        }
      }
    }
  }, [data, components]);

  async function readISO() {
    return fetch(window.location.origin + "/ISO-4217.csv").then(response => {
      const reader = response.body.getReader();
      return reader.read().then(result => {
        const decoder = new TextDecoder('utf-8');
        var csv = decoder.decode(result.value)
        csv = csv.split("\n")
        return csv
      })
    }).then(csv => {
      // var output = {}
      var output = []
      var log = []
      for (let line of csv) {
        let lineSep = line.split(",")
        if (!isNaN(lineSep[3]) && lineSep[3] && lineSep[5].length <= 1 && !log.includes(lineSep[3])) {
          output.push({"label": lineSep[1], "option": lineSep[3]})
          log.push(lineSep[3])
        }
      }
      return output
    }).then(out => {
      if (out === null || Object.keys(out).length === 0) {// quick fix for bug in prod
        return [{"option": "978", "label": "Euro"}, {"option": "840", "label": "Us Dollar"}, {"option" :"826", "label": "Pound Sterling"}]
      }
      return out
    })
  }

  useEffect(() => {
    if (selectedItem && selectedItem.id !== data.id && selectedItem.data.conditional) {
      layout.forEach((e, i) => {
        if (e.children[0].children[0].id === selectedItem.id && i < path.split("-")[0]) {
          setShrink(true)
        }
        if (e.children[0].children[0].id === selectedItem.id) {
          setSelectedPosition(i)
        }
      })
    } else {
      setShrink(false)
      setSelectedPosition(-1)
    }
    colorPick()
    getSubDephs()
  }, [selectedItem])

  function colorPick() {
    var nbrOtherColors = 0
    var selected = ""
    if (components[data.id].data.conditional) {
      selected = components[data.id].id
    } else {
      selected = components[data.id].data.sub
    }
    layout.forEach((e, i) => {
      if (components[e.children[0].children[0].id].data.conditional) {
        if (e.children[0].children[0].id === selected) {
          setColor(conditionalColor[nbrOtherColors % conditionalColor.length])
        }
        else {
          nbrOtherColors += 1
        }
      }
    })
  }

  function getSubDephs() {
    let localComp = components[data.id]
    let output = -1
    for (let y = 0; localComp?.data?.sub; y += 1) {
      localComp = components[localComp?.data?.sub]
      if (localComp && !localComp?.data?.sub) {
        // setSubDephs(y + 1)
        output = y + 1
      }
    }
    setSubDephs(output)
  }

  function colorPicker(selected) {
    var nbrOtherColors = 0
    layout.forEach((e, i) => {
      if (components[e.children[0].children[0].id].data.conditional) {
        if (e.children[0].children[0].id === selected) {
          return (conditionalColor[nbrOtherColors % conditionalColor.length])
        }
        else {
          nbrOtherColors += 1
        }
      }
    })
  }

  useEffect(() => {
    setSub(components[data.id].data.sub)
    colorPick()
    getSubDephs()
  }, [refresh])

  // useEffect(() => {
  //   console.log(path)
  //   console.log(components[data.id])
  // }, [path])

  useEffect(() => {
    if (components[data.id] && components[data.id].data.sub) {
      var position = -1
      layout.forEach((e, i) => {
        if (e.children[0].children[0].id === components[data.id].data.sub && i < path.split("-")[0]) {
          position = i
        }
      })
      if (path.split("-")[0] > 0 && components[layout[path.split("-")[0] - 1].children[0].children[0].id].data.sub !== components[data.id].data.sub && layout[path.split("-")[0] - 1].children[0].children[0].id !== components[data.id].data.sub) {
        // check if the block above is a sub from an other conditional that is himself from the same sub as the curent block
        if (!((components[layout[path.split("-")[0] - 1].children[0].children[0].id].data.sub && components[components[layout[path.split("-")[0] - 1].children[0].children[0].id].data.sub].data.sub === components[data.id].data.sub) || !components[layout[path.split("-")[0] - 1].children[0].children[0].id].data.sub)) {
          position = -1
        }
        if (!components[layout[path.split("-")[0] - 1].children[0].children[0].id].data.sub) {
          position = -1
        }
      }
      if (position === -1) {
        components[data.id].data.sub = ""
        setSub("")
        setShrink(false)
        setRefresh(((refresh + 1) % 5) + 1)
      } else {
        setShrink(true)
      }
    } else {
      setShrink(false)
    }
    colorPick()
  }, [layout])

  const [{ isDragging }, drag] = useDrag({
    type: COMPONENT,
    item: { type: COMPONENT, id: data.id, path },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  function onClick() {
    // if (type && type !== "custom input") {
    handleDetails(data.id, !(selectedItem === component.id));
    // }
  }

  //Triger when a custum input is modifed
  function onChangeCustom(e) {
    components[data.id].data.value = e.target.value
    setNotSave(true)
  }

  function onChangeImage(value) {
    uploadFile(value).then(res => {
      var fileJson = JSON.parse(res);
      components[data.id].data.value = fileJson.url;
      setNotSave(true);
      onSaveCustom()
    })
  }

  //Save button for custom input & switch show state
  function onSaveCustom() {
    save()
    setNotSave(false)
  }

  function subSelect() {
    if (sub && sub === selectedItem.id) { // unsub
      components[data.id].data.sub = ""
      layout.forEach((e, i) => {
        if (i > path.split('-')[0] && components[e.children[0].children[0].id].data.sub === selectedItem.id) {
          components[e.children[0].children[0].id].data.sub = ""
        }
      })
      setSub("")
    } else { // become a sub
      components[data.id].data.sub = selectedItem.id
      let selectedPath = 0
      layout.forEach((e, i) => {
        if (e.children[0].children[0].id === selectedItem.id) {
          selectedPath = i
        }
      })
      layout.forEach((e, i) => {
        if (i < path.split('-')[0] && i > selectedPath && ((components[components[e.children[0].children[0].id].data.sub] && !isSub(components[e.children[0].children[0].id])) || !components[e.children[0].children[0].id].data.sub)) {
          components[e.children[0].children[0].id].data.sub = selectedItem.id
        }
      })
      setSub(selectedItem.id)
    }
    setRefresh(((refresh + 1) % 5) + 1)
  }

  function isSub(comp) {
    if (comp) {
      if (comp.data.sub && comp.data.sub !== selectedItem.id) {
        return isSub(components[comp.data.sub])
      } else if (comp.data.sub === selectedItem.id) {
        return true
      }
    }
    return false
  }

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const component = components[data.id];

  function renderSwitch(type) {
    switch (component.content) {
      case "Stripe":
        if (component.data.options) {
          return (
            <div>
              <div className="w-full flex justify-between items-center" >
                <div>
                  <div className="flex flex-row flex-nowrap">
                    <div className="text-xl font-semibold text-gray-900">
                      {t("Stripe")}
                    </div>
                  </div>
                </div>
              </div>
              <Select1 save={save} data={components[data.id].data} className="h-10 px-3 bg-transparent border border-gray-300 rounded w-full"></Select1>
            </div>);
        } else return null;
      case "Paybox":
        return (<div className="flex flex-col">
          <div className="w-full flex justify-between items-center" >
            <div>
              <div className="flex flex-row flex-nowrap">
                <div className="text-xl font-semibold text-gray-900">
                  {t("Paybox")}
                </div>
              </div>
            </div>
          </div>
          {payment.types.length !== [...new Set(payment.types)].length && 
          <div className="flex flex-row pt-1 ">{payment && <div className="w-48"><Select onClick={e => { components[data.id].data.value = e; setNotSave(true) }} value={components[data.id].data.value} options={payment.Labels} keyValue="id" keyName="label" className="h-10 px-3 bg-transparent border border-gray-300 rounded w-full"></Select></div>}</div>}
          <div className="pt-1"><Prix defaultPrix={components[data.id].data?.options[0]?.value} setNotSave={setNotSave} component={components[data.id]}/></div>
          {/* <div className="pt-1"><input type="number" onChange={(e) => { components[data.id].data.options[0].value = e.target.value; setNotSave(true) }} defaultValue={components[data.id].data?.options[0]?.value} placeholder={"€"} className="h-10 px-3 bg-transparent border border-gray-300 rounded w-full" /></div> */}
          <div className="flex flex-row pt-1 ">{ISO4217 && <div className="w-48"><Select onClick={e => { components[data.id].data.options[1].value = e; setNotSave(true) }} value={components[data.id].data.options[1].value} options={ISO4217} keyValue="option" keyName="label" className="h-10 px-3 bg-transparent border border-gray-300 rounded w-full"></Select></div>}<button className="border bg-white rounded-lg p-2 shadow hover:bg-slate-200 focus:outline-none ml-4" onClick={save} >{t("Save")}</button></div>
        </div>)
      case "Description":
        return (<div className="flex items-center justify-between">
          <textarea rows="4" onChange={(e) => onChangeCustom(e)} defaultValue={components[data.id].data.value} placeholder={t(components[data.id].data.placeholder)} className="px-3 bg-transparent border border-gray-300 rounded w-full" />
          {notSave && <svg className="ml-3 w-4 h-4 text-blue-700 cursor-pointer" onClick={onSaveCustom} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
          </svg>
          }
        </div>)
      case "Title":
        return (<div className="flex items-center justify-between">
          <input onChange={(e) => onChangeCustom(e)} defaultValue={components[data.id].data.value} placeholder={t(components[data.id].data.placeholder)} className="h-10 px-3 bg-transparent border border-gray-300 rounded w-full" />
          {notSave && <svg className="ml-3 w-4 h-4 text-blue-700 cursor-pointer" onClick={onSaveCustom} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
          </svg>
          }
        </div>)
      case "Image":
        return (<div className="flex items-center justify-between">
          <URLLogo id={data.id} maxWidth={1920} maxHeight={1080} maxSize={300} value={components[data.id].data.value} onUpdate={(value) => onChangeImage(value)} />
        </div>)
      case "Video":
        return (<div className="flex items-center justify-between">
          <input onChange={(e) => onChangeCustom(e)} defaultValue={components[data.id].data.value} placeholder={t(components[data.id].data.placeholder)} className="h-10 px-3 bg-transparent border border-gray-300 rounded w-full" />
          {notSave && <svg className="ml-3 w-4 h-4 text-blue-700 cursor-pointer" onClick={onSaveCustom} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
          </svg>
          }
        </div>)
      case "Texte":
        return (<div className="flex items-center justify-between">
          <input onChange={(e) => onChangeCustom(e)} defaultValue={components[data.id].data.value} placeholder={t(components[data.id].data.placeholder)} className="h-10 px-3 bg-transparent border border-gray-300 rounded w-full" />
          {notSave && <svg className="ml-3 w-4 h-4 text-blue-700 cursor-pointer" onClick={onSaveCustom} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
          </svg>
          }
        </div>)
      case "Upload":
        return (<div className="w-full flex justify-between items-center" >
          <div>
            <div className="flex flex-row flex-nowrap">
              <div className="text-xl font-semibold text-gray-900 truncate">
                {component.data.label}
              </div>
            </div>
          </div>
        </div>)
      case "Logic":
        return (<div className="w-full flex justify-between items-center" >
          <div>
            <div className="flex flex-row flex-nowrap">
              {component.data.error && <ExclamationIcon className="h-7 w-7 text-red-500" />}
              <div className="text-xl font-semibold text-gray-900 truncate">
                {component.data.label}
              </div>
            </div>
          </div>
          <CogIcon className={"h-5 w-5 text-gray-500 cursor-pointer " + (component?.data?.optionsCustom ? "text-red-500 " : "")} onClick={() => onClick()} />
        </div>)
      case "Redirect":
        return (<div className="flex items-center justify-between">
          <div className="flex items-center justify-between w-full">
            <input onChange={(e) => { components[data.id].data.label = e.target.value; setNotSave(true) }} defaultValue={components[data.id].data.label} placeholder={t("display name")} className="h-10 px-3 bg-transparent border border-gray-300 rounded w-full" />
            <input onChange={(e) => onChangeCustom(e)} defaultValue={components[data.id].data.value} placeholder="url" className="h-10 px-3 bg-transparent border border-gray-300 rounded w-full" />
          </div>
          {notSave && <svg className="ml-3 w-4 h-4 text-blue-700 cursor-pointer" onClick={onSaveCustom} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
          </svg>
          }
        </div>)
      case "Recap":
        return (<div className="flex items-center justify-between">
          <div>
            <div className="flex flex-row flex-nowrap">
              <div className="text-xl font-semibold text-gray-900 truncate">
                {t("Recap")}
              </div>
            </div>
          </div>
        </div>)
      case "Empty":
        return (<div className="flex items-center justify-between">
          <div>
            <div className="flex flex-row flex-nowrap">
              <div className="text-xl font-semibold text-gray-900 truncate">
                {component.data.label ? component.data.label : component.data.name}
              </div>
            </div>
          </div>
        </div>)
      default:
        return (null)
    }
  }


  return refresh >= 0 ? (
    <div className="flex">
      <div
        ref={ref}
        style={{ opacity: opacity - ((selectedSubs && selectedSubs.length > 0 && selectedItem.id !== component.id && !selectedSubs.includes(component.id)) ? 0.5 : 0), width: (100 - (5 * subDephs)) + "%" }}
        className={"component draggable min-w-max bg-white shadow rounded-lg text-sm font-medium px-4 py-3 flex-inital" + (selectedItem && selectedItem.id === component.id ? " text-blue-700 bg-blue-100" : " text-gray-700") + ((components[data.id].data.conditional && color) ? " border-double border-4 " + color : "") + ((sub && color) ? " border-dashed border-2 " + color : "") + ((sub || shrink) ? " mx-auto" : " w-full") + ((component.data.hidden && type && type !== "custom input") ? " stripes" : "")}
      // onClick={() => type && type !== "custom input" ? onClick() : null}
      >
        <div style={{ width: "100%" }}>
          {type && type !== "custom input" ?
            <div className="w-full flex justify-between items-center" >
              <div>
                <div className="flex flex-row flex-nowrap">
                  {component.data.error && <ExclamationIcon className="h-7 w-7 text-red-500" />}
                  {component.data.required && <div className="text-xl font-semibold text-red-600">*</div>}
                  <div className={"text-xl font-semibold text-gray-900 truncate" + ((component.data.readOnly || component.data.hidden) ? " opacity-50" : "")}>
                    {component.data.label}
                  </div>
                  {component.data.tooltip &&
                    <>
                      <div id={component.id} data-tooltip-id="ComponentTooltip" data-tooltip-content={component.data.tooltip} className="h-fit w-fit">
                        <InformationCircleIcon className="ml-1 mt-2 h-4 text-gray-500" />
                      </div>
                    </>
                  }
                  <div className={"ml-2 italic text-xl font-semibold text-gray-400 truncate" + ((component.data.readOnly || component.data.hidden) ? " opacity-70 " : "")}>{component.data.name}</div>
                </div>
                {component.data.description && <div className="mb-2 font-normal text-left text-gray-400">{component.data.description}</div>}
              </div>
              <CogIcon className={"h-5 w-5 text-gray-500 cursor-pointer " + (component?.data?.optionsCustom ? "text-red-500 " : "")} onClick={() => type && type !== "custom input" ? onClick() : null} />
            </div> : null}
          {renderSwitch(type)}
        </div>
      </div>
      {(shrink && selectedItem && (!sub || sub === selectedItem.id) && selectedPosition < path.split("-")[0]) &&
        <button
          className={isSub(component) ? 'bg-blue-600 h-4 w-4 mt-0.5 cursor-pointer shrink-0 rounded-full flex-none self-center' : 'border border-gray-500 h-4 w-4 mt-0.5 cursor-pointer shrink-0 rounded-full flex-none self-center ml-2'}
          onClick={subSelect}
          aria-hidden="true" type="button"
        >
          <span className={sub === selectedItem.id ? "bg-gray-200 rounded-full w-1.5 h-1.5" : "bg-gray-100 rounded-full w-1.5 h-1.5"}>
            <span className={sub === selectedItem.id ? 'bg-blue-600 bg-opacity-25 w-full h-full' : 'bg-gray-100 w-full h-full'} />
          </span>
        </button>
      }
      {(shrink && selectedItem && sub !== selectedItem.id && isSub(component) && selectedPosition < path.split("-")[0]) &&
        <div className="w-4"></div>
      }
      <Tooltip id={"ComponentTooltip"} place="top" variant="info" />
    </div>
  ) : (<div></div>);
};
export default Component;