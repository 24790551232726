import { getAccessToken } from "../../auth/verifyAuth";

async function getStripeProducts() {
    var accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/stripe/products", {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getStripePrices() {
    var accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/stripe/prices", {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}



export {
    getStripeProducts,
    getStripePrices
}