export default function Structure({ id, title, component, error }) {
  return (
    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <div>
        <label
          htmlFor={id}
          className={error ? "block text-sm font-medium text-red-800 sm:mt-px sm:pt-2" : "block text-sm font-medium text-gray-800 sm:mt-px sm:pt-2"}
        >
          {title}
        </label>
      </div>
      <div className="sm:col-span-2">{component()}</div>
    </div>
  );
}
