import { v4 as uuidv4 } from 'uuid';
import Content from './../../components/DragNDrop/Content';

const fieldGroup = {
    id: "********",
    object: "name+obj",
    objectId: "e_mail",
    label: "E-mail",
    type: "string",
    required: false,
    visible: true,
    component: "TextInput",
    showCondition: {},
    subQuestions: []
}

const newObject = {
    name: "",
    type: "DEAL",
    association: "DEAL_to_HUBSPOT"
}

const newStepEx = {
    name: "TestFormulaire Candidat",
    questions: [
    ]
}

const newForm = {
    "id": "string",
    "parameters": {
        "Nom": "",
        "Niveau d'entrée": "",
        "Durée": "",
        "Langues": "",
        "Campus": "",
        "Cursus": "",
        "Rentrée": "",
        "Description": "",
        "Pipelines": "",
        "enable": true,
    },
    "objectsName": [],
    "steps": []
}

const selectOption = {
    label: "",
    value: ""
}

const TypeCorolation = {
    text: "TextInput",
    calculation_equation: "calculation_equation",
    select: "Select",
    date: "date",
    number: "number",
    textarea: "TextArea",
    checkbox: "Checkbox",
    radio: "Radio",
    Logic: "Logic"
}

// to create a new form : HubsptForm(name) or HubsptForm(field, name)
// to add a new field at the end : HubsptForm(form, field)
// to add a new field betwin two fields : HubsptForm(form, field, place)
function UpdateHubspotForm(form, field, place, replace) {
    let localfieldGroup = JSON.parse(JSON.stringify(fieldGroup));
    let localOption = {};
    if (form) {
        if (field) {
            // console.log(JSON.parse(JSON.stringify(form)));
            // console.log(JSON.parse(JSON.stringify(field)));
            localfieldGroup.id = uuidv4();
            localfieldGroup.name = field.name;
            localfieldGroup.objectId = field.name;
            localfieldGroup.label = field.label;
            localfieldGroup.object = field.name;
            localfieldGroup.component = TypeCorolation[field.fieldType];
            localfieldGroup.description = field.description;
            localfieldGroup.groupName = field.groupName;
            localfieldGroup.conditional = false;
            localfieldGroup.conditionalList = null;
            localfieldGroup.sub = field?.sub;
            localfieldGroup.selected = false;
            if (field.fieldType) {
                localfieldGroup.fieldType = field.fieldType;
            }
            if (field.options && field.options.length) {
                localfieldGroup.options = field.options;
            }
            if (field.fieldType in ["select", "checkbox", "radio"]) {
                localfieldGroup.options = [];
                field.options.forEach(o => {
                    localOption = JSON.parse(JSON.stringify(selectOption));
                    localOption.label = o.label;
                    localOption.value = o.value;
                    localfieldGroup.options.push(localOption);
                })
            }
            if (field.fieldType === "custom input") {
                localfieldGroup.value = ""
            }
            if (field.readOnly) {
                localfieldGroup.readOnly = field.readOnly
            } else {
                localfieldGroup.readOnly = false
            }
            localfieldGroup.id = field.id
            if (replace) {
                form.questions[place] = JSON.parse(JSON.stringify(localfieldGroup));
            } else {
                if (place !== null && place < (form.questions.length) && place >= 0) {
                    form.questions.splice(place, 0, JSON.parse(JSON.stringify(localfieldGroup)));
                } else {
                    form.questions.push(JSON.parse(JSON.stringify(localfieldGroup)));
                }
            }
        }
        return form;
    }
}

function NewStep(name, field) {
    let localnewStepEx = JSON.parse(JSON.stringify(newStepEx));
    let localfieldGroup = JSON.parse(JSON.stringify(fieldGroup));
    let localOption = {};
    if (name) {
        localnewStepEx.name = name;
        if (field) {
            localfieldGroup.id = uuidv4();
            localfieldGroup.name = field.name;
            localfieldGroup.label = field.label;
            localfieldGroup.component = TypeCorolation[field.fieldType];
            localfieldGroup.description = field.description;
            localfieldGroup.tooltip = field.tooltip;
            if (field.fieldType === "select") {
                localfieldGroup.options = [];
                field.options.forEach(o => {
                    localOption = JSON.parse(JSON.stringify(selectOption));
                    localOption.label = o.label;
                    localOption.value = o.value;
                    localfieldGroup.options.push(localOption);
                })
            }
            localnewStepEx.questions.push(localfieldGroup);
        }
        return JSON.parse(JSON.stringify(localnewStepEx));
    }
}

function NewHubspotForm(id) {
    let localNewForm = JSON.parse(JSON.stringify(newForm));
    if (id) {
        localNewForm.id = id
    } else {
        localNewForm.id = uuidv4();
    }
    return JSON.parse(JSON.stringify(localNewForm));
}

function SetObjects(form) {
    let objectsName = form.Step.map(x => (x.questions.map(y => y.object)));
    let allObjectsName = [];
    let objects = [];
    objectsName.forEach(obj => {
        obj.forEach(e => {
            allObjectsName.push(e);
        })
    });
    allObjectsName = [...new Set(allObjectsName)];
    allObjectsName.forEach(obj => {
        newObject.name = obj;
        objects.push(JSON.parse(JSON.stringify(newObject)));
    })
    form.objectsName = JSON.parse(JSON.stringify(objects));
    return form;
}

export {
    UpdateHubspotForm,
    NewStep,
    NewHubspotForm,
    SetObjects
}