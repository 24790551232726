export default function Card({ title, children, noPadding }) {
  return (
    <div className="rounded-lg bg-white shadow overflow-y-auto" style={{ maxHeight: "inherit" }}>
      <div className={noPadding ? "p-0" : "p-4"} style={{ maxHeight: "inherit" }}>
        {title && <h2 className={noPadding ? "text-base font-medium text-gray-900 text-2xl flex justify-between pt-4 pl-6" : "text-base font-medium text-gray-900 text-2xl flex justify-between"}>
          {title}
        </h2>}
        <div className={title ? "mt-2" : ""} style={{ maxHeight: "inherit" }}>
          {children}
        </div>
      </div>
    </div >)
}