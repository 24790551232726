const configuration = {
  auth: {
    authority:"https://exaduob2c.b2clogin.com/exaduob2c.onmicrosoft.com/B2C_1_create_admin_account",
    clientId: "b941af8d-9cc9-4f06-a6e2-a604a60428b2",
    knownAuthorities: ["exaduob2c.b2clogin.com"],
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

const loginRequest = {
  scopes: ["openid", "offline_access"],
};

const protectedResources = {
  api: {
    endpoint: window.location.origin + "/api",
        scopes: ["https://exaduob2c.onmicrosoft.com/b941af8d-9cc9-4f06-a6e2-a604a60428b2/candidature.readwrite"]
  },
};

export { configuration, loginRequest, protectedResources };
