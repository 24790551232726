const includesInField = function (obj, key, search) {
    if(typeof obj === "string")
    {
        return obj === null
        ? false
        : obj
            .toString()
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
                search
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            );
    } else {
        return obj[key] === null
        ? false
        : obj[key]
            .toString()
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
                search
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
            );
    }
};

const includesInObject = function (obj, search) {
    return Object.keys(obj).some(function (key) {
        if (obj[key] && obj[key] !== null) {
            if (typeof obj[key] === "object") {
                return includesInObject(obj[key], search);
            } else {
                return includesInField(obj, key, search);
            }
        } else {
            return false;
        }
    });
};

export default function getContentFiltered(data, search) {
    var contentFiltered = data;
    if (search !== null && search.toString() !== "") {
        if (data && data.length > 0) {
            contentFiltered = data.filter((obj) => {
                return includesInObject(obj, search);
            });
        }
    }
    return contentFiltered;
}
