import { ExclamationIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { t } from "i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

function capitalize(str) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str
}
function isValidDate(date) {
    let isValidDate = Date.parse(date);
    return !isNaN(isValidDate)
}

function CandidatureCard({ candidature, candidatureId, amount, displayList }) {
    let navigate = useNavigate();

    return <Link
        key={candidatureId}
        className={classNames(
            candidatureId === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
            candidatureId === 1 ? 'sm:rounded-tr-lg' : '',
            candidatureId === amount - 1 ? (amount % 2 === 0 ?
                'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : 'rounded-bl-lg sm:rounded-bl-none') : '',
            'relative group bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500 cursor-pointer'
        )}
        to={"/" + candidature.id + "/informations"}
        // onClick={() => navigate("/" + candidature.id + "/informations")}
        // onAuxClick={() => navigate("/" + candidature.id + "/informations")}
    >
        <div className="px-4 pt-4">
            <div className="focus:outline-none flex space-x-3 items-start justify-between">
                <h3 className="truncate text-sm font-medium text-gray-900">
                    {candidature.name ? candidature.name : "-"}
                    <p className="text-sm text-gray-500 font-normal">
                        {candidature.curriculum}
                    </p>
                </h3>
                <div className="inline-flex">
                {<span className={classNames(candidature.enabled ? "text-green-800 bg-green-100" : "bg-red-100 text-red-800", "inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium ")}>
                    {candidature.enabled ? t("Actif") : t("Non Actif")}
                </span>}
                {candidature.Error && <ExclamationIcon className="ml-2 h-6 w-6 text-red-500" />}
                </div>
            </div>

        </div>
        {<div className="px-4 py-4  flex">
            {displayList.map(disp => candidature[disp.name] && <span key={uuidv4()} className={classNames("text-gray-800 bg-gray-100", "inline-flex items-center rounded-md px-3 py-0.5 text-sm font-medium mr-2")}>
                {candidature[disp.name]}
            </span>)}
            {/* {candidature.campus && <span className={classNames("text-gray-800 bg-gray-100", "inline-flex items-center rounded-md px-3 py-0.5 text-sm font-medium mr-2")}>
                {candidature.campus}
            </span>}
            {candidature.languages && <span className={classNames("text-gray-800 bg-gray-100", "inline-flex items-center rounded-md px-3 py-0.5 text-sm font-medium mr-2")}>
                {candidature.languages}
            </span>}
            {candidature.start_academic_year && isValidDate(candidature.start_academic_year) ? <span className={classNames("text-gray-800 bg-gray-100", "inline-flex items-center rounded-md px-3 py-0.5 text-sm font-medium ")}>
                {capitalize((new Date(candidature.start_academic_year)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'long' }))}
            </span> : null} */}
        </div>}
    </Link >
}

export default CandidatureCard;