import classNames from "classnames";
import { useState } from "react";
import { withTranslation } from "react-i18next";

function verifyPath(json, path) {
    var exists = true;
    if (path) {
        var steps = path.split('.');
        if (steps.length > 1) {
            steps.forEach(step => {
                if (json && json[step]) {
                    json = json[step];
                } else {
                    exists = false;
                }
            })
        } else {
            if (!json) {
                exists = false;
            }
        }
    } else {
        exists = false;
    }
    return exists;
}

function Layout({ label, description, tooltip, children }) {
    return <div className="px-4">
        <label htmlFor="first-name" className="mt-4 block text-sm font-medium text-gray-700">
            {label}
        </label>
        <p className="text-xs text-gray-400">{description}</p>
        <div className="mt-1">
            {children}
        </div>
    </div>
}

function Visualizer({ t, candidature, theme }) {
    const [currentStep, setCurrentStep] = useState(0);
    const event = new Event("resize")

    window.dispatchEvent(event)

    function handleComponentVisual(question, index) {
        if (question.fieldType === "custom input") {
            switch (question.label) {
                case "Image":
                    return <img
                        className={classNames(index === 0 ? "" : "mt-4", "h-auto w-full")}
                        src={question.value?.split(/;(.*)/s)[1]}
                        alt={question.value}
                    />
                case "Texte":
                    return <h3 className="mt-4 px-4 text-lg leading-6 font-medium text-gray-900">{question.value}</h3>
                case "Title":
                    return <h3 className="mt-4 px-4 text-lg leading-6 font-medium text-gray-900">{question.value}</h3>
                case "Texte long":
                    return <p className="px-4 mt-1 text-sm text-gray-500">{question.value}</p>
                case "Stripe":
                    return <p className="mt-4 mx-4 px-4 py-5 rounded-md" style={verifyPath(theme, "secondary_color") ? { backgroundColor: theme.secondary_color } : null}>
                        <div className="flex items-center justify-between">
                            <div className="text-white">{question.value ? question.options.find(opt => opt.value === question.value).label : " "}</div>
                            <button className="text-white border border-white rounded-md p-2 w-48">{t("Proceed to payment")}</button>
                        </div>
                    </p>
                case "Paybox":
                    return <p className="mt-4 mx-4 px-4 py-5 rounded-md" style={verifyPath(theme, "secondary_color") ? { backgroundColor: theme.secondary_color } : null}>
                        <div className="flex items-center justify-between">
                            <div className="text-white">{question.value ? question.options.find(opt => opt.value === question.value).label : " "}</div>
                            <button className="text-white border border-white rounded-md p-2 w-48">{t("Proceed to payment")}</button>
                        </div>
                    </p>
                default:
                    return <>Not implemented : {question.label}</>
            }
        } else {
            switch (question.fieldType) {
                case "select":
                    return <Layout {...question}>
                        <select className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3">
                            {question.options.map(opt => <option key={opt.value}>{opt.label}</option>)}
                        </select></Layout>
                case "text":
                    return <Layout {...question}>
                        <input
                            type="text"
                            className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"
                        />
                    </Layout>
                case "number":
                    return <Layout {...question}>
                        <input
                            type="number"
                            className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"
                        />
                    </Layout>
                case "date":
                    return <Layout {...question}>
                        <input
                            type="date"
                            className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"
                        />
                    </Layout>
                case "booleancheckbox":
                    return <Layout {...question}>
                        <div role="group">
                            {question.options.map(opt => <div key={opt.value} className="mt-2 ml-2 space-y-2">
                                <div className="flex items-center">
                                    <input
                                        id={opt.value}
                                        name={opt.label}
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label htmlFor={opt.value} className="ml-3 block text-sm font-medium text-gray-700">
                                        {opt.label}
                                    </label>
                                </div>
                            </div>)}
                        </div>
                    </Layout>
                default:
                    return <>Not implemented : {question.component}</>;
            }
        }
    }

    return candidature && theme ? <div className="bg-gray-100" style={verifyPath(theme, "font") ? { fontFamily: theme.font } : null}>
        <header style={verifyPath(theme, "primary_color") ? { backgroundColor: theme.primary_color } : null}>
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
                <div className="w-full py-6 flex items-center justify-between border-b lg:border-none" style={verifyPath(theme, "primary_color") ? { borderColor: theme.primary_color } : null}>
                    <div className="flex items-center">
                        <div>
                            <span className="sr-only">{t("Company Logo")}</span>
                            {verifyPath(theme, "url_logo") ? <img
                                className="h-10 w-auto"
                                src={theme.url_logo?.split(/;(.*)/s)[1]}
                                alt="Logo"
                            /> : null}
                        </div>
                    </div>
                    <div className="ml-10 space-x-4">
                        <div
                            className="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium"
                            style={verifyPath(theme, "primary_color") ? { color: theme.primary_color } : null}
                        >
                            {t("Log out")}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        <div>
            <div>

                <div className="space-y-0 bg-white">
                    {candidature.Parameters && <div className="px-6 pt-5">
                        <h2 className="text-2xl leading-6 font-medium text-gray-900">{candidature.Parameters.name}</h2>
                        {candidature.Parameters.description && <p className="mt-1 text-sm text-gray-500">{candidature.Parameters.description}</p>}
                    </div>}
                    {candidature.Step ? <div className="pt-5">
                        <nav aria-label="Progress">
                            <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8 px-4">
                                {candidature.Step.map((step, stepIdx) => (
                                    <li key={stepIdx} className="md:flex-1 cursor-pointer" onClick={() => { setCurrentStep(stepIdx) }}>
                                        {stepIdx <= currentStep ? (
                                            <a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 md:pl-0 md:pb-0 md:border-l-0 md:border-t-4" style={verifyPath(theme, "secondary_color") ? { borderColor: theme.secondary_color } : null}>
                                                <span className="text-md font-medium" style={verifyPath(theme, "secondary_color") ? { color: theme.secondary_color } : null}>{step.name}</span>
                                            </a>
                                        ) : (
                                            <a
                                                href={step.href}
                                                className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pb-0 md:border-l-0 md:border-t-4"
                                            >
                                                <span className="text-md text-gray-400 font-medium group-hover:text-gray-700">{step.name}</span>
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ol>
                        </nav>
                    </div> : null}
                    <div>
                        <div className="block" aria-hidden="true">
                            <div className="pt-5">
                                <div className="border-t border-gray-200" />
                            </div>
                        </div>
                        <div className="pb-5">
                            {candidature.Step[currentStep].questions.map((question, index) => <div key={index}>{handleComponentVisual(question, index)}</div>)}
                        </div>
                        <div className="block" aria-hidden="true">
                            <div className="py-5">
                                <div className="border-t border-gray-200" />
                            </div>
                        </div>
                        <div className="p-5 pt-2">
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                                    style={{ backgroundColor: theme.secondary_color }}
                                >
                                    {t("Save")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    </div> : null
}

export default withTranslation()(Visualizer);