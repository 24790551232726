import { Fragment, useEffect, useState } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import { screen } from '@testing-library/react';

const sortOptions = [
    { name: 'Oldest', id: "old" },
    { name: 'Newest', id: "new" },
]

function getSortedData(type, listCandidatures) {
    if (type === "old") {
        return listCandidatures.sort((a, b) => { if (a.modificationDate < b.modificationDate) { return -1 } return 1 }) // à sort avec la date
    } else {
        return listCandidatures.sort((a, b) => { if (a.modificationDate > b.modificationDate) { return -1 } return 1 })
    }
}

function compareDates(a, b) {
    if (a.modificationDate > b.modificationDate) {
        return -1
    } return 1
}

function getFilteredData(filters, listCandidatures) {
    var cands = listCandidatures;
    filters.forEach(filter => {
        const values = filter?.options?.filter(opt => opt.actif)
        if (values && values.length) {
            cands = cands.filter(c => values.find(v => v.value === c[filter.id]));
        }
    });
    return cands
}

function FilterBarCandidature({ t, candidatures, setFilteredCandidatures, filterList }) {
    const [filters, setFilters] = useState(null);
    const [sortType, setSortType] = useState(null);

    useEffect(() => {
        if (candidatures && candidatures.length) {
            var filtersContruction = [];
            // filtersContruction = candidatures[0]?.parameters?.map(p => { return { id: p.name, name: p.label } });
            // filtersContruction = filtersContruction.map(p => {
            //     p.options = candidatures.map(c => c.parameters?.find(pa => pa.name === p.id && pa.filter)?.value).filter((value, index, self) => self.indexOf(value) === index).filter(c => c !== "" && c !== null && c !== undefined).map(c => { return { value: c, label: c, actif: false } })
            //     return p
            // }).filter(p => p?.options?.length > 1)
            filterList?.map(filter => {
                filtersContruction.push({
                    id: filter.name,
                    name: filter.label,
                    options: filter.options.map(o => { return { value: o.value, label: o.label, actif: false } })
                    // options: candidatures.map(c => c[filter.name]).filter((value, index, self) => self.indexOf(value) === index).map(c => { return { value: c, label: c, actif: false } })
                })
            })
            filtersContruction.push({
                id: "enabled",
                name: "Actif",
                options: [{ value: true, label: t("Actif"), actif: true }, { value: false, label: t("Non actif"), actif: false }]
            })
            setFilters(filtersContruction);
            setFilteredCandidatures(getSortedData(null, getFilteredData(filtersContruction, candidatures)));
        }
    }, [candidatures, t, setFilteredCandidatures]);

    function handleOnChange(type, filter) {
        setFilters(filter);
        setSortType(type);
        setFilteredCandidatures(getSortedData(type, getFilteredData(filter, candidatures)));
    }


    return filters && filters.length ? <div className="flex items-center justify-between px-2">
        <Menu as="div" className="relative z-10 inline-block text-left">
            <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    {t("Sort")}
                    <ChevronDownIcon
                        className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-left absolute left-0 z-10 mt-2 w-40 rounded-md shadow-2xl bg-white">
                    <div className="py-1">
                        {sortOptions.map((option) => (
                            <Menu.Item key={option.id} onClick={() => handleOnChange(option.id, filters)}>
                                {({ active }) => (
                                    <div
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm font-medium text-gray-900 cursor-pointer'
                                        )}
                                    >
                                        {t(option.name)}
                                    </div>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>

        <Popover.Group className="flex items-baseline space-x-2 sm:space-x-8">
            {filters.map((section, sectionIdx) => (
                <Popover
                    as="div"
                    key={section.name}
                    id={`desktop-menu-${sectionIdx}`}
                    className="relative z-10 inline-block text-left"
                >
                    <div>
                        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                            <span>{section.name}</span>
                            {section?.options?.filter(opt => opt.actif).length > 0 ? (
                                <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                                    {section.options.filter(opt => opt.actif).length}
                                </span>
                            ) : null}
                            <ChevronDownIcon
                                className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                        </Popover.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Popover.Panel className="overflow-auto max-h-[70vh] origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4">
                            <form className="space-y-4">
                                {section?.options?.map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center cursor-pointer">
                                        <input
                                            onChange={() => {
                                                var filtersLocal = filters;
                                                var filterLocal = filters[sectionIdx];
                                                var optionLocal = filterLocal.options[optionIdx];
                                                optionLocal.actif = !optionLocal.actif;
                                                filterLocal.options[optionIdx] = optionLocal;
                                                filtersLocal[sectionIdx] = filterLocal;
                                                handleOnChange(sortType, filtersLocal)
                                            }}
                                            id={`filter-${section.id}-${optionIdx}`}
                                            name={`${section.id}[]`}
                                            checked={option.actif}
                                            type="checkbox"
                                            className="h-4 w-4 border-gray-300 rounded text-indigo-600 cursor-pointer"
                                        />
                                        <label
                                            htmlFor={`filter-${section.id}-${optionIdx}`}
                                            className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                                        >
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </form>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            ))}
        </Popover.Group>
    </div> : null
}

export default withTranslation()(FilterBarCandidature);