import { Listbox, Transition } from "@headlessui/react";
import {  CheckIcon, ChevronDownIcon, SearchIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useState } from "react";
import getContentFiltered from "../../utils/functions/filteredData";

// options = ["aaa", "bbb", "ccc"...] or options = [{"label": "aaa", "option": "123"}, {"label" : "bbb", "option": "456"}...] keyValue = "option" keyName = "label"
export default function Select({ value, onClick, options, keyValue, keyName }) {
    const [optionsFiltered, setOptionsFiltered] = useState([]);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        setOptionsFiltered(getContentFiltered(options, filter));
    }, [options, filter])

    return keyValue && keyName ? <div className="w-full">
        <Listbox
            value={value}
            onChange={onClick}
        >
            <div className="relative">
                <Listbox.Button className="relative text-left block h-8 w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-1 pl-3 cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-primary-300 focus-visible:ring-offset-2 focus-visible:border-primary-500 sm:text-sm z-10">
                    <span className="block truncate pr-5">{optionsFiltered && optionsFiltered.find(opt => opt[keyValue] === value) ? optionsFiltered.find(opt => opt[keyValue] === value)[keyName] : null}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronDownIcon className="w-5 h-5 text-gray-400"
                            aria-hidden="true" />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
                        <div className="px-2 my-1 relative w-full text-gray-400 focus-within:text-gray-600">
                            <div className="absolute pl-3 inset-y-0 left-0 flex items-center pointer-events-none">
                                <SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                            </div>
                            <input className="block w-full bg-light-gray-700 bg-opacity-50 py-1 2xl:py-2 pl-10 pr-3 border border-gray-300 rounded-md leading-5 placeholder-light-primary-100 focus:outline-none focus:bg-white focus:ring-primary-700 focus:border-primary-500 focus:placeholder-gray-500 focus:text-gray-900 text-xs 2xl:text-sm"
                                placeholder="Search"
                                name="search"
                                autoFocus value={filter} onChange={(event) => {
                                    var value = event.target.value
                                    setFilter(value);
                                }} />
                        </div>
                        {optionsFiltered.map((option, id) => (
                            <Listbox.Option
                                key={id}
                                className={() =>
                                    `${option[keyValue] === value ? 'text-primary-900 bg-primary-100' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                                }
                                value={option[keyValue]}
                            >
                                <>
                                    <span
                                        className={`${option[keyValue] === value ? 'font-medium' : 'font-normal'
                                            } block`}
                                    >
                                        {option[keyName]}
                                    </span>
                                    {option[keyValue] === value ? (
                                        <span
                                            className="text-primary-600 absolute inset-y-0 left-0 flex items-center pl-3"
                                        >
                                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                </>
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    </div>
        :
        <div className="w-full">
            <Listbox
                value={value}
                onChange={onClick}
            >
                <div className="relative">
                    <Listbox.Button className="relative text-left h-8 block w-full sm:text-sm border border-gray-300 rounded-md p-1 pl-3 cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-primary-300 focus-visible:ring-offset-2 focus-visible:border-primary-500 sm:text-sm">
                        <span className="block truncate pr-5">{optionsFiltered && optionsFiltered.find(opt => opt === value) ? optionsFiltered.find(opt => opt === value) : null}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronDownIcon className="w-5 h-5 text-gray-400"
                            aria-hidden="true" />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <div className="px-2 my-1 relative w-full text-gray-400 focus-within:text-gray-600">
                                <div className="absolute pl-3 inset-y-0 left-0 flex items-center pointer-events-none">
                                    <SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                </div>
                                <input className="block w-full bg-light-gray-700 bg-opacity-50 py-1 2xl:py-2 pl-10 pr-3 border border-gray-300 rounded-md leading-5 placeholder-light-primary-100 focus:outline-none focus:bg-white focus:ring-primary-700 focus:border-primary-500 focus:placeholder-gray-500 focus:text-gray-900 text-xs 2xl:text-sm"
                                    placeholder="Search"
                                    name="search"
                                    autoFocus value={filter} onChange={(event) => {
                                        var value = event.target.value
                                        setFilter(value);
                                    }} />
                            </div>
                            {optionsFiltered.map((option, id) => (
                                <Listbox.Option
                                    key={id}
                                    className={() =>
                                        `${option === value ? 'text-primary-900 bg-primary-100' : 'text-gray-900'}
                          cursor-default select-none relative py-2 pl-10 pr-4`
                                    }
                                    value={option}
                                >
                                    <>
                                        <span
                                            className={`${option === value ? 'font-medium' : 'font-normal'
                                                } block`}
                                        >
                                            {option}
                                        </span>
                                        {option === value ? (
                                            <span
                                                className="text-primary-600 absolute inset-y-0 left-0 flex items-center pl-3"
                                            >
                                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                            </span>
                                        ) : null}
                                    </>
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
}
