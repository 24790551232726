import { TrashIcon, PlusCircleIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import SaveButton from "../Buttons/Save";
import StepPopup from "../Popup/stepPopup";

function Step({ t, steps, currStep, onClick, input = -1, save, handleRemoveStep, handleAddStep, handleMoveStep, stepJson }) {
  const theme = { color: { primary: "#3b82f6" } };
  const [menu, setMenu] = useState(false)
  function onInput(e, index) {
    if (e.key === "Enter") {
      steps[index].name = document.getElementById("step-curent-name").value;
      onClick({ "detail": -1 }, index);
      save();
    } else if (e.key === "Escape") {
      onClick({ "detail": -1 }, index);
    }
  }

  function saveName(newName) {
    steps[currStep].name = newName;
    onClick({ "detail": -1 }, currStep);
    save();
  }

  return <nav aria-label="Progress" className="h-full">
    <ol className="h-full rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
      {steps.map((step, stepIdx) => (
        <li key={stepIdx} onClick={(e) => onClick(e, stepIdx)} className="relative md:flex-1 md:flex">
          <div>{step.description}</div>
          {stepIdx === currStep ? (
            <div className="pl-6 pr-3 py-4 flex items-center text-sm font-medium w-full" aria-current="step">
              <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary rounded-full"
                style={theme && theme.color && theme.color.primary ? { borderColor: theme.color.primary } : null}
              >
                <span className="text-primary"
                  style={theme && theme.color && theme.color.primary ? { color: theme.color.primary } : null}
                >{stepIdx + 1}</span>
              </span>
              {input !== stepIdx ?
                <span className="ml-4 items-center text-sm font-medium text-primary flex justify-between w-full"
                  style={theme && theme.color && theme.color.primary ? { color: theme.color.primary } : null}
                >{step.name ? step.name : t("Double Click to title")}
                  {/* {handleRemoveStep && steps.length > 1 ? <TrashIcon className="ml-2 h-5 w-5 text-red-500 cursor-pointer" onClick={handleRemoveStep} /> : null} */}
                  {handleRemoveStep ? <button className="text-primary-500 cursor-pointer hover:text-primary-700" onClick={() => setMenu(true)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="30px" height="30px" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                  </svg></button> : null}
                  {menu && <StepPopup title={step.name} cancel={() => setMenu(false)} step={steps} remove={handleRemoveStep} changeName={saveName} saveMove={handleMoveStep} stepJson={stepJson}/>}
                </span>
                :
                <div className="flex items-center justify-between">
                  <input id="step-curent-name" defaultValue={step.name} onKeyDown={(e) => onInput(e, stepIdx)} className="border border-gray-300 h-8 ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                  </input>
                  <SaveButton loading={false} onClick={(e) => {e.key = "Enter";onInput(e, stepIdx)}} />
                  {/* <svg className="ml-3 w-4 h-4 text-blue-700 cursor-pointer"
                  onClick={(e) => {e.key = "Enter";onInput(e, stepIdx)}}
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                  </svg> */}
                </div>
              }
            </div>
          ) : (
            <div className="group flex items-center">
              <span className="px-6 py-4 flex items-center text-sm font-medium">
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                  <span className="text-gray-500 group-hover:text-gray-900">{stepIdx + 1}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name ? step.name : t("Double Click to title")}</span>
              </span>
            </div>
          )}
          {/* Arrow separator for lg screens and up */}
          <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
            <svg
              className="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vectorEffect="non-scaling-stroke"
                stroke="currentcolor"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </li>
      ))}
      <li key="addStep" onClick={handleAddStep} className="relative md:flex-1 md:flex w-16">
        <div className="group flex items-center">
          <span className="px-6 py-4 flex items-center text-sm font-medium">
            <span className="flex-shrink-0 w-10 h-10 justify-center">
              <span className="text-gray-500">
                <div className="h-full w-fit px-1 mx-4 my">
                  <div id="tooltipPlusDescription" className="h-fit w-fit">
                    <PlusCircleIcon className="h-10 w-10 text-blue-500 cursor-pointer" />
                  </div>
                </div>
              </span>
            </span>
          </span>
        </div>
      </li>
    </ol>
    <Tooltip anchorSelect="#tooltipPlusDescription" effect="solid" place="top" type="info" content={t("Ajouter une étape")} />
  </nav>
}

export default withTranslation()(Step);
