import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useParams } from 'react-router';
import SearchBar from "./Data/SearchBar";
import Card from "../../../components/Card/Card";
import Center1Column from "../../../layout/Page/Center/1column";
import Status from "../../../components/Buttons/Status";
import FakeText from "../../../components/Fake/FakeText";
import FakeList from "../../../components/Fake/FakeList";
import FakeCard from "../../../components/Fake/FakeCard";
import Texty from "../../../components/Text/TooltipWhenTooLong";
import { TrashIcon, ExclamationIcon } from "@heroicons/react/outline";
import CollapseAccordion from "../../../components/Accordion/CollapseAccordion";
import { v4 as uuidv4 } from 'uuid';
import { GenerateLightForms, formData, getHubspotSkeleton, updateDataForm } from "../../../utils/requests/cosmo";
import { toast } from "react-toastify";

function capitalize(str) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str
}

function Data({ t }) {
    const [loading, setLoading] = useState(true);
    const [schemas, setSchemas] = useState(null);
    const [defaultSchemas, setDefaultSchemas] = useState(null);
    const [group, setGroup] = useState(null);
    const [groupSchemas, setGroupShemas] = useState(null);
    const [selectedSchemas, setSelectedSchemas] = useState(null);
    const [upToDate, setUpToDate] = useState(false);
    const [defCand, setCand] = useState(false);
    const [usedNames, setUsedNames] = useState([]);
    const [errorList, setErrorList] = useState([]);
    let { id } = useParams();

    useEffect(() => {
        setLoading(true);
        setUpToDate(false);
        getHubspotSkeleton().then(form => {
            if (form) {
                form.properties = form.properties.filter(e => e.hidden === false && e.modificationMetadata && !e.modificationMetadata.readOnlyValue);
                setDefaultSchemas(form.properties);
                setSchemas(form.properties);
                let groupAPI = form.properties.map(p => p.groupName).sort().filter((v, i, s) => s.indexOf(v) === i);
                setGroup(groupAPI);

                let items = []
                groupAPI.forEach(g => {
                    let groupList = []
                    form.properties.forEach(schema => {
                        if (schema.groupName === g) {
                            groupList.push(schema);
                        }
                    });
                    items.push(groupList);
                });
                setGroupShemas(items);
                setUpToDate(true);
                setLoading(false);
            }
        })
    }, []);

    useEffect(() => {
        if (id) {
            formData(id).then(cand => {
                setCand(cand);
                if (cand.Data) {
                    setSelectedSchemas(cand.Data);
                } else {
                    setSelectedSchemas([]);
                }
                let localNames = []
                cand.Step.forEach(step => {
                    step.questions.forEach(question => {
                        if (question.name !== null) {
                            localNames.push(question.name)
                        }
                    })
                })
                let errors = []
                cand.ErrorsId.forEach(err => {
                    if (err.slice(err.lastIndexOf(':') + 1) === "question") {
                        errors.push(err.slice(0, err.lastIndexOf(':')))
                    }
                })
                setErrorList(errors)
                setUsedNames(localNames);
            })
            // getCandidatureById(id).then(candidature => {
            //     // if (candidature.data) {
            //     //     setSelectedSchemas(candidature.data);
            //     // } else {
            //     //     setSelectedSchemas([]);
            //     // }
            // })
        }
    }, [id])

    useEffect(() => {
        if (selectedSchemas && defaultSchemas) {
            selectedSchemas.forEach(selected => {
                for (let index = 0; index < defaultSchemas.length; index++) {
                    if (defaultSchemas[index].name === selected.name && defaultSchemas[index].groupName === selected.groupName) {
                        // defaultSchemas[index].selected = selected.selected;//is problematic if saved weird
                        defaultSchemas[index].selected = true;//could be problematic for the research
                        break;
                    }
                }
            })
        }
    }, [selectedSchemas, defaultSchemas])

    function saveSelected(selected) {
        // getCandidatureById(id).then(candidature => {
        formData(id).then(candidature => {
            candidature.Data = selected;
            updateDataForm(candidature).then(res => {
                if (res.ok) {
                    setUpToDate(true)
                } else {
                    setUpToDate(false);
                }
                GenerateLightForms()
            })
        })
    }

    function updateSelected() {
        let schemasList = []
        defaultSchemas.forEach(schema => {
            if (schema.selected) {
                schemasList.push(schema);
            }
        })
        setSelectedSchemas(schemasList);
        saveSelected(schemasList);
    }

    function updateTable(allForms, selected) {
        if (usedNames.includes(selected.name)) {
            toast.warn(selected.label + t(" is used in the designer"));
            return
        }
        schemas.forEach((s, i) => {
            if (s.groupName === selected.groupName && s.name === selected.name) {
                allForms = i
            }
        })
        schemas[allForms].selected = !schemas[allForms].selected;
        for (let index = 0; index < defaultSchemas.length; index++) {
            if (defaultSchemas[index].name === schemas[allForms].name && defaultSchemas[index].groupName === schemas[allForms].groupName) {
                defaultSchemas[index].selected = schemas[allForms].selected;
                break;
            }
        }
        updateSelected();
    }

    return <Center1Column
        middleHeader={<div>
            <div className="flex justify-between">
                <h2 className="text-left text-base font-medium text-gray-900 text-2xl">{t("Sélecteur de données")}</h2>
                {!loading && <div className="h-8 w-8">
                    <Status loading={upToDate === "loading"} success={upToDate} />
                </div>}
            </div>
            <p className="text-gray-500 text-light">{t("Dans cette page, vous pourrez sélectionner les propriétés de l'objet Transaction déterminées sur hubspot dont vous aurez besoin dans votre formulaire.")}</p>
            {!loading && defaultSchemas && group ? <SearchBar defaultSchemas={defaultSchemas} group={group} setGroupShemas={setGroupShemas} /> : null}
        </div>}
        sideBarRight
        sideBar={<div className="hidden lg:flex lg:flex-col lg:gap-y-2" style={{ maxHeight: "inherit" }}>
            <Card noPadding>
                <div className="p-4 flex justify-between w-full">
                    <div className="flex">
                        <h2 className="text-base font-medium text-gray-900 text-2xl">{t("Summary")}</h2>
                    </div>
                </div>
                {loading ? [...Array(3)].map(() => <div className="pb-2" key={uuidv4()}><FakeText /></div>) :
                    <div className="overflow-y-auto h-fit">
                        {groupSchemas.map((s, i) =>
                            s.filter((objet) => selectedSchemas && selectedSchemas.find(s => objet.groupName === s.groupName && objet.name === s.name)).length > 0 ?
                                <div key={uuidv4()} className="mb-2 border-t p-4">
                                    {s.length !== 0 ?
                                        <div>
                                            <legend className="text-base font-medium text-gray-900">{capitalize(group[i]).replace(/_/g, " ")}</legend>
                                            {groupSchemas && s.filter((objet) => selectedSchemas && selectedSchemas.find(s => objet.groupName === s.groupName && objet.name === s.name)).length > 0 ?
                                                <div className="my-2">
                                                    {s.map((objet, objetId) => selectedSchemas && selectedSchemas.find(s => objet.groupName === s.groupName && objet.name === s.name) ? (
                                                        <span key={uuidv4()} className="w-full overflow-hidden h-8 flex rounded-md items-center pl-1 pr-2 text-sm font-medium bg-slate-100 text-slate-700 mb-1 justify-between">
                                                            <div className="w-full pr-2 overflow-hidden">
                                                                <div className="flex items-center">
                                                                    {/* <div className="text-slate-400">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="5px" height="24px">
                                                                            <path fill="currentColor" d="M0 0h2v2H0V0zm0 8h2v2H0V8zm0 8h2v2H0v-2zM0 4h2v2H0V4zm0 8h2v2H0v-2zm0 8h2v2H0v-2zM3 0h2v2H3V0zm0 8h2v2H3V8zm0 8h2v2H3v-2zM3 4h2v2H3V4zm0 8h2v2H3v-2zm0 8h2v2H3v-2z" />
                                                                        </svg>
                                                                    </div> */}
                                                                    {errorList.includes(objet.name) && <ExclamationIcon className="ml-2 h-6 w-6 text-red-500" />}
                                                                    <Texty>
                                                                        <div className="w-full">
                                                                            <div className="pl-2">{objet.label}</div>
                                                                        </div>
                                                                    </Texty>
                                                                </div>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white"
                                                                onClick={() => updateTable(objetId, objet)}
                                                            >
                                                                <span className="sr-only">Remove property</span>
                                                                <TrashIcon className="h-4 w-4" color="red" />
                                                            </button>
                                                        </span>
                                                    ) : <span key={uuidv4()} className="w-full overflow-hidden h-8 flex rounded-md items-center pl-1 pr-2 text-sm font-medium bg-slate-100 text-slate-700 mb-1 justify-between">
                                                    <div className="w-full pr-2 overflow-hidden">
                                                        <div className="flex items-center">
                                                            {/* <div className="text-slate-400">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="5px" height="24px">
                                                                    <path fill="currentColor" d="M0 0h2v2H0V0zm0 8h2v2H0V8zm0 8h2v2H0v-2zM0 4h2v2H0V4zm0 8h2v2H0v-2zm0 8h2v2H0v-2zM3 0h2v2H3V0zm0 8h2v2H3V8zm0 8h2v2H3v-2zM3 4h2v2H3V4zm0 8h2v2H3v-2zm0 8h2v2H3v-2z" />
                                                                </svg>
                                                            </div> */}
                                                            {errorList.includes(objet.name) && <ExclamationIcon className="ml-2 h-6 w-6 text-red-500" />}
                                                            <Texty>
                                                                <div className="w-full">
                                                                    <div className="pl-2">{objet.label}</div>
                                                                </div>
                                                            </Texty>
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white"
                                                        onClick={() => updateTable(objetId, objet)}
                                                    >
                                                        <span className="sr-only">Remove property</span>
                                                        <TrashIcon className="h-4 w-4" color="red" />
                                                    </button>
                                                </span>)}
                                                </div>
                                                : null}
                                        </div>
                                        : null}
                                </div> : null
                        )}
                    </div>}
            </Card >
        </div >}
    >
        <div className="w-full">
            {!loading ? <CollapseAccordion items={groupSchemas.filter(sch => sch && sch.length)}
                visual={(s, i) => <legend key={uuidv4()} className="text-left font-medium text-gray-800 text-xl">
                    {/* {capitalize(group[i]).replace(/_/g, " ")} */}
                    {capitalize(s[0].groupName).replace(/_/g, " ")}
                    {groupSchemas && s.filter((objet) => selectedSchemas && selectedSchemas.find(s => objet.groupName === s.groupName && objet.name === s.name)).length > 0 ? <span className="text-blue-800 bg-blue-100 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium ml-2">
                        {s.filter((objet) => selectedSchemas && selectedSchemas.find(s => objet.groupName === s.groupName && objet.name === s.name)).length}
                    </span> : <></>}
                </legend>}
                contentVisual={(s, i) => <div key={uuidv4()} className="divide-y divide-gray-200">
                    {s.map((objet, objetId) => (
                        <div key={objetId} className={
                            selectedSchemas && selectedSchemas.find(s => objet.groupName === s.groupName && objet.name === s.name) ? 'bg-blue-600 bg-opacity-25 border-blue-600 flex px-3 py-3 cursor-pointer pl-5' : 'flex px-3 py-3 cursor-pointer pl-5'

                        }
                            onClick={() => updateTable(objetId, objet)}>
                            <span
                                className={selectedSchemas && selectedSchemas.find(s => objet.groupName === s.groupName && objet.name === s.name) ? 'bg-blue-600 h-4 w-4 mt-0.5 cursor-pointer shrink-0 rounded-full' : 'border border-gray-500 h-4 w-4 mt-0.5 cursor-pointer shrink-0 rounded-full'}

                                aria-hidden="true"
                            >
                                <span className={selectedSchemas && selectedSchemas.find(s => objet.groupName === s.groupName && objet.name === s.name) ? "bg-gray-200 rounded-full w-1.5 h-1.5" : "bg-gray-100 rounded-full w-1.5 h-1.5"}>
                                    <span className={selectedSchemas && selectedSchemas.find(s => objet.groupName === s.groupName && objet.name === s.name) ? 'bg-blue-600 bg-opacity-25 w-full h-full' : 'bg-gray-100 w-full h-full'} />
                                </span>
                            </span>
                            <span className="ml-3 flex flex-col">
                                <span
                                    className={selectedSchemas && selectedSchemas.find(s => objet.groupName === s.groupName && objet.name === s.name) ? 'text-blue-900 block text-sm font-medium' : 'text-gray-900 block text-sm font-medium'}
                                >
                                    {objet.label}
                                </span>
                            </span>
                        </div>
                    ))}
                </div>} />
                : <FakeList component={() => <div className="pb-2"><FakeCard /></div>} length={1} />}
        </div>
    </Center1Column >
}

export default withTranslation()(Data);