import React, { Suspense } from "react";

// language
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { LoadingComponent } from "./auth/components";
import i18n from "./locale/i18n";

// Notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Router
import Router from "./utils/router/router";

import 'tw-elements';

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Suspense fallback={<LoadingComponent />}>
          <ToastContainer/>
          <Router />
        </Suspense>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;



