import { getAccessToken } from "../../auth/verifyAuth";

async function getDefaultTheme() {
    var accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/defaulttheme", {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function setDefaultTheme(theme) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/defaulttheme", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(theme)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function getImage(url) {
    if (url) {
        return fetch(url, {
            method: "GET"
        })
    }
    else {
        return false;
    }
}

export {
    getDefaultTheme,
    setDefaultTheme,
    getImage
}