import React from 'react';
import Informations from './Tabs/Informations';
import Data from './Tabs/Data';
import Designer from './Tabs/Designer';
import Validation from './Tabs/Validation';

const onglets = [
    {
        name: 'Accueil',
        href: null
    },
    {
        name: 'Informations',
        href: 'informations',
        component: Informations
    },
    {
        name: 'Données',
        href: 'data',
        component: Data
    },
    {
        name: 'Editeur',
        href: 'designer',
        component: Designer
    },
    {
        name: 'Validation',
        href: 'validation',
        component: Validation
    }
]

const renderTabPanel = (onglet) => {
    var currentPageParams = onglets.find(ong => ong.href === onglet);

    if (currentPageParams && currentPageParams.component) {
        var Component = currentPageParams.component;
        return (<Component />
        );
    } else {
        // redirect 404
    }

};



export {
    onglets,
    renderTabPanel
}