import { useEffect, useRef, useState } from "react";

function Full3Columns({ left, right, children, onScroll, middleHeader }) {
    const refPage = useRef(null);
    const [pageStyle, setPageStyle] = useState(null);

    useEffect(() => {
        setPageStyle("100%");
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (refPage && refPage.current && refPage.current.offsetTop) {
            let diff = window.innerHeight - refPage.current.offsetTop - 60;
            setPageStyle(diff);
        }
    }, [refPage])

    // useEffect(() => {
    //     // à corriger, se répète en boucle
    //     if (onOffsetHeightChange && refPage && refPage.current && refPage.current.offsetHeight) {
    //         onOffsetHeightChange();
    //     }
    // }, [])

    function handleResize() {
        if (refPage && refPage.current && refPage.current.offsetHeight > 0) {
            setPageStyle((window.innerHeight - refPage.current.offsetTop -60));
        }
    }

    return <div className="grid md:grid-cols-5 h-full">
        <div className="hidden lg:block col-span-1 px-2 lg:px-4" style={{ maxHeight: pageStyle + 100 }}>
            {left}
        </div>
        <section className="col-span-5 md:col-span-3">
            <div className="max-w-3xl lg:max-w-7xl mx-auto pb-3">{middleHeader}</div>
            <div className=" max-w-3xl lg:max-w-7xl mx-auto overflow-y-auto" style={{ "height": pageStyle }} 
            // onScroll={() => onScroll(refPage)}
             onLoad={() => handleResize()} ref={refPage}>
                {children}
            </div>
        </section>
        <div className="hidden md:block col-span-2 lg:col-span-1 px-2 lg:px-4" style={{ maxHeight: pageStyle + 100 }}>
            {right}
        </div>
    </div>
}

export default Full3Columns;