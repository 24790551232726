import { useNavigate } from "react-router";

function RowIconListItem({ icon, title, description, link, buttonLabel, buttondeactivate }) {
    let navigate = useNavigate();
    return <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
            <span className="inline-block relative">
                {icon}
            </span>
        </div>
        <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 truncate">{title}</p>
            {description && <p className="text-sm text-gray-500 truncate">{description}</p>}
        </div>
        <div>
            {!buttondeactivate && <div
                onClick={() => { navigate(link) }}
                className="cursor-pointer inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
            >
                {buttonLabel}
            </div>}
        </div>
    </div>
}

export default RowIconListItem;