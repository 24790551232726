import { useState, useEffect } from 'react'
import { Listbox } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid';

export default function SimpleSelect({ value, setValue, options, labels, setOnHover, disabled = false }) {
    // function localOnHover(e) {
    //     if (setOnHover) {
    //         setOnHover(e)
    //     }
    // }

    // return (labels) && <div>
    //     <select id="location" onChange={e => setValue(e.target.value)} name="location" className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" value={value} >
    //         <option key={0}></option>
    //         {options && options.length && options.length === labels.length ?
    //             labels.map((label, i) => (<option onMouseEnter={() => localOnHover(options[i])} onMouseLeave={() => localOnHover("")} key={i + 1} value={options[i]}>{label}</option>))
    //             :
    //             labels.map((label, i) => (<option onMouseEnter={() => localOnHover(label)} onMouseLeave={() => localOnHover("")} key={i + 1}>{label}</option>))
    //         }
    //     </select>
    // </div>
    const [display, setDisplay] = useState([""]);

    useEffect(() => {
        let local = ""
        if (value) {
            if (options && options.length === labels.length) {
                local = labels[options.indexOf(value)]
            } else {
                local = value
            }
        }
        setDisplay(local)
      }, [value]);

      function localOnHover(e) {
        if (setOnHover) {
            setOnHover(e)
        }
      }

      function localSave(e) {
        localOnHover("")
        setValue(e)
      }

    return (
        <Listbox disabled={disabled} value={value} onChange={localSave}>
            <Listbox.Button className={"flex h-8 mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-0 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"}>
                <span className="flex-1 truncate w-full">{display}</span>
                <span className="flex-none pointer-events-none inset-y-0 right-0 items-center">
                    <ChevronDownIcon
                        className="h-5 w-5 text-slate-500"
                        aria-hidden="true"
                    />
                </span>
            </Listbox.Button>
            <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options && options.length && options.length === labels.length ?
                    labels.map((label, i) => (
                        <Listbox.Option onMouseEnter={() => localOnHover(options[i])} onMouseLeave={() => localOnHover("")} className={(value === options[i] ? "bg-primary text-white" : "bg-white text-black") + " cursor-default select-none py-2 pl-10 pr-4 hover:bg-sky-700 hover:text-white"} key={options[i]} value={options[i]}>
                            {label}
                        </Listbox.Option>
                    ))
                    :
                    labels.map((label, i) => (
                        <Listbox.Option onMouseEnter={() => localOnHover(label)} onMouseLeave={() => localOnHover("")} className={(value === label ? "bg-primary text-white" : "bg-white text-black") + " cursor-default select-none py-2 pl-10 pr-4 hover:bg-sky-700 hover:text-white"} key={label} value={label}>
                            {label}
                        </Listbox.Option>
                    ))
                }
            </Listbox.Options>
        </Listbox>
    )
}