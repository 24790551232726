import React from "react";

export default class Input extends React.Component {
  render() {
    var { id, register, parameters, ...props } = this.props
    return register ? <input
      name={id}
      id={"input-" + id}
      type="text"
      className="block w-full sm:text-sm border border-gray-300 p-4 focus:outline-none focus:ring-0 focus:border-primary"
      {...register(id, parameters)}
      {...props}
    /> : <input
      name={id}
      id={"input-" + id}
      type="text"
      className="block w-full sm:text-sm border border-gray-100 p-4 h-2 focus:outline-none focus:ring-0 focus:border-primary"
      {...props}
    />
  }

}