import React, { useRef, useState, useEffect } from "react";
import { useDrag } from "react-dnd";
import { COMPONENT } from "./constants";
import { InformationCircleIcon } from '@heroicons/react/outline';
import Select from "../Blank/Select";
import Checkbox from "../Blank/Checkbox";
import {Tooltip} from "react-tooltip";

const Content = ({ component, components, path, optionMenu, save }) => {
  const [data, setData] = useState(null);
  const [selected, setSelected] = useState(null);
  const ref = useRef(null);
  const [type, setType] = useState(null);
  components[data.id].ref = ref;

  useEffect(() => {
    console.log("comp")
    if (component) {
      setData(component.data);
      if (component.data) {
        setSelected(component.data.selected);
        setType(component.data.fieldType);
      }
    }
  }, [component])

  const [{ isDragging }, drag] = useDrag({
    type: COMPONENT,
    item: { type: COMPONENT, id: data.id, path },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  function onClick() {
    if (type && type !== "custom input") {
      const state = optionMenu(component);
      setSelected(state);
      for (const [key, value] of Object.entries(components)) {
        components[key].data.selected = false
      }
      components[data.id].data.selected = state;
    }
  }

  function onChangeCustom(e) {
    components[data.id].data.data = e.target.value
  }

  function onInputCustom(e) {
    if (e.key === "Enter") {
      save()
    }
  }

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  function renderSwitch(type) {
    console.log(type)
    console.log(component)
    switch (type) {
      case "text":
        return (<input className="h-10 bg-white border border-gray-300 rounded w-full" />)
      case "select":
        return (<Select data={components[data.id].data}></Select>)
      case "date":
        return (<input type="date" className="h-10 px-3 bg-white border border-gray-300 rounded w-full">
        </input>)
      case "booleancheckbox":
        return (<Checkbox data={components[data.id].data}></Checkbox>)
      case "textarea":
        return (<textarea className="h-20 bg-white border border-gray-300 rounded w-full">
        </textarea>)
      case "custom input":
        if (component.content === "Stripe" && component.data.options) {
          return (<Select save={save} data={components[data.id].data} className="h-10 px-3 bg-white border border-gray-300 rounded w-full"></Select>)
        } else if (component.content === "Paybox") {
          console.log(components[data.id])
          return (<input onKeyDown={(e) => onInputCustom(e)} onChange={(e) => onChangeCustom(e)} defaultValue={components[data.id].data.data} placeholder={components[data.id].data.placeholder} className="h-10 px-3 bg-white border border-gray-300 rounded w-full"></input>)
        } else if (component.content === "Empty") {
          return (<div>{components[data.id].label}</div>)
        } else {
          return (<input onKeyDown={(e) => onInputCustom(e)} onChange={(e) => onChangeCustom(e)} defaultValue={components[data.id].data.data} placeholder={components[data.id].data.placeholder} className="h-10 px-3 bg-white border border-gray-300 rounded w-full"></input>)
        }
      default:
        return (<input className="h-10 bg-white border border-gray-300 rounded w-full" />)
    }
  }
  return (
    <div
      ref={ref}
      style={{ opacity }}
      onClick={() => onClick()}
      className={"component draggable w-full min-w-max bg-white text-sm font-medium text-gray-700 p-2" + (selected ? " border-2 border-blue-300" : "")}
    >
      <div>
        <div className="flex flex-row flex-nowrap">
          <div className="mb-2 pl-4 text-left text-lg font-medium text-gray-700">{component.data.label}</div>
          {component.data.tooltip &&
            <>
              <div id={component.id} data-tooltip-id="ContentTooltip" data-tooltip-content={component.data.tooltip} className="h-fit w-fit">
                <InformationCircleIcon className="ml-1 mt-2 h-4 text-gray-500" />
              </div>
              <Tooltip id="ContentTooltip" effect="solid" place="top" varient="info"/>
            </>
          }
        </div>
        <div className="mb-2 pl-1 text-left text-lg font-light text-gray-500">{component.data.description}</div>
        <>
          {renderSwitch(type)}
        </>
      </div>
    </div>
  );
};
export default Content;