import { withTranslation } from "react-i18next";
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Input from "../Inputs/Input";
import { ArrowLeftIcon, ArrowRightIcon, CodeIcon } from "@heroicons/react/outline";
import JsonPopup from "./jsonPopup";
import 'react-json-view-lite/dist/index.css';


function StepPopup({ t, cancel, title, step, remove, saveMove, changeName, stepJson }) {
    const [open, setOpen] = useState(true)
    // const [newName, setNewName] = useState("")
    const [currPos, setCurrPos] = useState(-1)
    const [localSteps, setLocalSteps] = useState([])
    const [jsonDisplay, setJsonDisplay] = useState(false)

    // useEffect(() => {
    //     setNewName(title)
    //     // if (step) {
    //     //     console.log(step)
    //     //     step.forEach((st, i) => {
    //     //         if (st.name === title) {
    //     //             setCurrPos(i)
    //     //         }
    //     //     });
    //     // }
    // }, [title]);

    useEffect(() => {
        if (step) {
            step.forEach((st, i) => {
                if (st.name === title) {
                    setCurrPos(i)
                }
            });
            // setLocalSteps(Object.assign([],step))
            setLocalSteps(JSON.parse(JSON.stringify(step)))
        }
    }, [step, title]);

    const cancelButtonRef = useRef(null)
    const erraseButtonRef = useRef(null)

    useEffect(() => {
        if (open === false) {
            cancel()
        }
    }, [open, cancel])

    function move(pos) {
        var newPos = pos + currPos
        if (newPos < 0 || newPos >= localSteps.length) {
            return
        }
        var outputSteps = []
        localSteps.forEach((st, i) => {
            if (i === newPos) {
                if (newPos < currPos) {
                    outputSteps.push(localSteps[currPos])
                    outputSteps.push(st)
                } else {
                    outputSteps.push(st)
                    outputSteps.push(localSteps[currPos])
                }
            } else if (i !== currPos && st.name !== localSteps[currPos].name) {
                outputSteps.push(st)
            }
        })
        setLocalSteps(outputSteps)
        setCurrPos(newPos)
    }

    function save() {
        var oldPosition = -1
        step.forEach((st, i) => {
            if (st.name === title) {
                oldPosition = i
            }
        });
        if (title !== localSteps[currPos].name && localSteps[currPos].name) {
            changeName(localSteps[currPos].name)
        }
        if (oldPosition !== currPos) {
            saveMove(oldPosition, currPos)
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="">
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                {t(title)}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                            {localSteps && <div className="flex justify-between py-2">
                                                <div className="w-1/3">{t("Name")}</div>
                                                <Input type="text" defaultValue={title} onChange={e => localSteps[currPos].name = e.target.value} className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3" />
                                            </div>}
                                            <div className="grid grid-cols-3 gap-4 py-2">
                                                {localSteps && localSteps.map((st, i) => {
                                                    if (i === currPos -1 || i === currPos || i === currPos +1 || (currPos === 0 && i === 2) || (currPos === localSteps.length - 1 && i === currPos - 2)) {
                                                        return (
                                                            <div key={i} className={"border text-center " + (i === currPos && "bg-primary-600 text-white")}>
                                                                <div>{st.name}</div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                            <div className="flex py-2 px-4 justify-between">
                                                <ArrowLeftIcon className="h-5 w-5 hover:text-primary-600" onClick={() => {move(-1)}}/>
                                                <ArrowRightIcon className="h-5 w-5 hover:text-primary-600" onClick={() => {move(1)}}/>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-6 sm:w-auto"
                                        onClick={() => {setOpen(false);save()}}
                                    >
                                        {t("Save")}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:ml-6"
                                        onClick={() => {setOpen(false);cancel()}}
                                        ref={cancelButtonRef}
                                    >
                                        {t("Cancel")}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 sm:mt-0 sm:w-auto sm:ml-6"
                                        onClick={() => {setOpen(false);remove()}}
                                        ref={erraseButtonRef}
                                    >
                                        {t("Delete")}
                                    </button>
                                    <button type="button" onClick={() => {setJsonDisplay(true)}} className="ml-1 p-2 w-fit rounded-md bg-slate-50 text-white hover:bg-slate-100 border border-slate-200"><CodeIcon className="w-5 h-5 text-slate-600"/></button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                    {jsonDisplay ? <JsonPopup
                    json={stepJson.Step[currPos]}
                    title={title}
                     cancel={() => {setJsonDisplay(false)}}/> : <></>}
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default withTranslation()(StepPopup);