import React, { useEffect, useState, } from "react";
import { SearchIcon } from '@heroicons/react/outline';
import getContentFiltered from "../../../../utils/functions/filteredData";
import { withTranslation } from "react-i18next";

function SearchBar({ t, defaultSchemas, group, setGroupShemas }) {
    const [filter, setFilter] = useState("");
    // const [typeFilter, setTypeFilter] = useState(null);

    useEffect(() => {
        if (filter !== null && filter.toString() !== "") {
            let formlist = getContentFiltered(defaultSchemas, filter);
            let largeGroupList = []
            group.forEach(groupSelected => {
                let groupList = []
                formlist.forEach(schema => {
                    if (schema && schema.groupName === groupSelected) {
                        groupList.push(schema);
                    }
                });
                largeGroupList.push(groupList);
            });
            setGroupShemas(largeGroupList);
        } else if (group && defaultSchemas) {
            let largeGroupList = []
            group.forEach(groupSelected => {
                let groupList = []
                defaultSchemas.forEach(schema => {
                    if (schema.groupName === groupSelected) {
                        groupList.push(schema);
                    }
                });
                largeGroupList.push(groupList);
            });
            setGroupShemas(largeGroupList);
        }
    }, [filter, defaultSchemas, group, setGroupShemas])

    return <div className="flex row py-3">
        <div className="w-full focus-within:text-gray-600">
                <label htmlFor="search" className="sr-only">
                    Search Hubspot objects
                </label>
                <div className="relative text-gray-400 focus-within:text-gray-400">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                        id="search"
                        name="search"
                        className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-white text-gray-500 placeholder-gray-400 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                        placeholder={t("Search")}
                        type="search"
                        value={filter} onChange={(event) => {
                            var value = event.target.value
                            setFilter(value);
                        }}
                    />
                </div>
        </div>
    </div>
}

export default withTranslation()(SearchBar);