import { withTranslation } from "react-i18next";
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from "@heroicons/react/outline";

import "./FullLoader.css";

export default function FullLoader() {
    const [open, setOpen] = useState(true)

    const cancelButtonRef = useRef(null)
    return (
        <Transition.Root show={true} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div>
                                    <div className="flex justify-center items-center">
                                        <div role="status">
                                            {/* <svg className="inline w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg> */}
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="192" height="192">
                                                <g transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)" fill="#FEFEFE" stroke="none">
                                                    <g className="clockwise">
                                                    <path d="M777 1896 c-187 -100 -579 -337 -613 -371 -22 -22 -51 -63 -65 -90
                                                    l-24 -50 0 -420 0 -420 26 -50 c52 -97 64 -106 454 -332 294 -171 262 -158
                                                    395 -158 93 0 122 4 150 19 19 10 161 91 315 180 295 170 344 207 387 293 23
                                                    48 23 51 26 454 3 391 2 409 -18 462 -37 99 -83 135 -415 328 l-300 174 -135
                                                    3 c-129 3 -137 2 -183 -22z m578 -230 c186 -109 303 -184 327 -209 67 -71 69
                                                    -88 66 -508 -3 -414 -3 -410 -72 -481 -36 -36 -574 -353 -640 -376 -27 -9 -64
                                                    -12 -108 -9 -75 5 -65 0 -453 225 -209 121 -264 163 -298 225 -22 41 -22 48
                                                    -22 432 0 366 1 392 19 429 34 67 76 98 342 253 372 216 322 195 444 191 l105
                                                    -3 290 -169z"/>
                                                    </g>
                                                </g>
                                                {/* <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 0 0" to="360 0 0" dur="2s" repeatCount="indefinite"/> */}
                                                <g transform="translate(0.000000,192.000000) scale(0.100000,-0.100000)" fill="#FEFEFE" stroke="none">
                                                    <g className="conterclockwise">
                                                    <path d="M725 1409 c-64 -18 -121 -85 -140 -161 l-7 -28 371 0 372 0 -3 98 -3
                                                    97 -280 2 c-154 1 -293 -3 -310 -8z"/>
                                                    <path d="M582 856 l3 -214 30 -43 c17 -23 48 -52 70 -63 38 -20 55 -21 335
                                                    -21 l295 0 3 98 3 97 -271 0 -270 0 0 80 0 80 195 0 195 0 0 100 0 100 -296 0
                                                    -295 0 3 -214z"/>
                                                    </g>
                                                </g>
                                                {/* <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 0 0" to="360 0 0" dur="2s" repeatCount="indefinite"/> */}
                                            </svg>
                                            <span className="sr-only">Loading...</span>
                                        </div></div>
                                    </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
    // (<div className="flex justify-center items-center">
    // <div role="status">
    //     <svg className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
    //         <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
    //     </svg>
    //     <span className="sr-only">Loading...</span>
    // </div></div>)
}