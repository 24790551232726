import { XCircleIcon, XIcon } from "@heroicons/react/outline";
import { withTranslation } from "react-i18next";
import React, { useState } from "react";

function Banner({ t, text, description}) {
    const [display, setDisplay] = useState(true);

  return display && (
    <div className="relative isolate flex items-center gap-x-6 sm:gap-x-2 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 border border-red-600 bg-red-200 mb-2">
      <div className="flex flex-wrap items-center gap-x-4 sm:gap-x-2 gap-y-2">
        <p className="font-bold text-red-900">
          {t(text)}
        </p>
        <p className="text-xs text-red-800">
          {t(description)}
        </p>
        {/* <a
          href="#"
          className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Register now <span aria-hidden="true">&rarr;</span>
        </a> */}
      </div>
      <div className="flex flex-1 justify-end">
        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]" onClick={e => setDisplay(false)}>
          <span className="sr-only">Dismiss</span>
          <XIcon aria-hidden="true" className="h-6 w-6 text-red-900 hover:text-red-600"/>
        </button>
      </div>
    </div>
  )
}

export default withTranslation()(Banner);