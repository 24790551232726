import React from "react";
import { SearchIcon } from '@heroicons/react/outline';
import getContentFiltered from "../../../../utils/functions/filteredData";
import { withTranslation } from "react-i18next";

function SearchBar({ t, data, handleOnChange }) {

return <div className="flex row border-t">
    <div className="w-full focus-within:text-gray-600">
        <label htmlFor="search" className="sr-only">
            Search selected objects for form
        </label>
        <div className="relative text-gray-400 focus-within:text-gray-400">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            <input
                id="search"
                name="search"
                className="block w-full pl-10 pr-3 py-2 border border-transparent leading-5 bg-gray-100 text-gray-500 placeholder-gray-400 focus:outline-none focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                placeholder={t("Search")}
                type="search"
                onChange={(event) => {
                    var value = event.target.value
                    handleOnChange(getContentFiltered(data, value));
                }}
            />
        </div>
    </div>
</div>
}

export default withTranslation()(SearchBar);