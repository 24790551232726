import { useEffect, useState } from "react";
import Select from "../../../components/Inputs/Select";
import Input from "../../../components/Inputs/Input";

const durationTypes = ["jours", "mois", "années"];

const maxByType = {
    jours: 31,
    mois: 12,
    années: 15
}


function Duration({ duree, onChange }) {
    const [duration, setDuration] = useState("");
    const [durationType, setDurationType] = useState(null);

    useEffect(() => {
        if(duree)
        {
            setDuration(duree.replace(/\D/g, '').replace(" ", ''))
            setDurationType(duree.replace(/\d/g, '').replace(" ", ''))
        }
    }, [duree])

    function handleOnChange(time, type) {
        if (maxByType[type] < time) {
            onChange(maxByType[type] + " " + type);
        } else {
            onChange(time + " " + type);
        }
    }

    return <>
        <Input type="number" onChange={(event) => { handleOnChange(event.target.value, durationType) }} max={durationType ? maxByType[durationType] : null} value={duration} className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3" />
        <Select options={durationTypes} value={durationType} onClick={(value) => { handleOnChange(duration, value) }} />
    </>
}

export default Duration;