import React from "react";
import { useDrag } from "react-dnd";
import TooltipWhenTooLong from "../Text/TooltipWhenTooLong";

const SideBarItem = ({ data }) => {
  const [{ opacity }, drag] = useDrag({
    type: data.type,
    item: data,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });

  function renderSwitch(type) {
    // switch (type) {
    //   case "text":
    //     //
    //     return (<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    //       width="24px" height="24px" viewBox="0 0 66.000000 66.000000"
    //       preserveAspectRatio="none">

    //       <g transform="translate(0.000000,66.000000) scale(0.100000,-0.100000)"
    //         fill="#000000" stroke="none">
    //         <path d="M150 325 l0 -95 185 0 185 0 0 95 0 95 -185 0 -185 0 0 -95z m350 0 l0 -75 -165 0 -165 0 0 75 0 75 165 0 165 0 0 -75z" />
    //         <path d="M210 333 c5 -2 8 -8 6 -12 -3 -4 0 -13 5 -21 8 -12 9 -9 7 9 -2 14 -9 26 -16 27 -7 1 -8 0 -2 -3z" />
    //         <path d="M269 331 c9 -6 11 -16 7 -27 -6 -15 -4 -16 5 -7 14 14 5 43 -13 43 -10 0 -9 -3 1 -9z" />
    //         <path d="M303 324 c-3 -8 0 -21 6 -27 9 -9 11 -8 5 7 -4 11 -2 21 7 27 10 6 11 9 1 9 -7 0 -15 -7 -19 -16z" />
    //       </g>
    //     </svg>)
    //   case "number":
    //     return (<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    //       width="24px" height="24px" viewBox="0 0 66.000000 66.000000"
    //       preserveAspectRatio="none">

    //       <g transform="translate(0.000000,66.000000) scale(0.100000,-0.100000)"
    //         fill="#000000" stroke="none">
    //         <path d="M150 325 l0 -95 185 0 185 0 0 95 0 95 -185 0 -185 0 0 -95z m350 0 l0 -75 -165 0 -165 0 0 75 0 75 165 0 165 0 0 -75z" />
    //         <path d="M210 333 c5 -2 8 -8 6 -12 -3 -4 0 -13 5 -21 8 -12 9 -9 7 9 -2 14 -9 26 -16 27 -7 1 -8 0 -2 -3z" />
    //         <path d="M269 331 c9 -6 11 -16 7 -27 -6 -15 -4 -16 5 -7 14 14 5 43 -13 43 -10 0 -9 -3 1 -9z" />
    //         <path d="M303 324 c-3 -8 0 -21 6 -27 9 -9 11 -8 5 7 -4 11 -2 21 7 27 10 6 11 9 1 9 -7 0 -15 -7 -19 -16z" />
    //       </g>
    //     </svg>)
    //   case "select":
    //     return (<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    //       width="24px" height="24px" viewBox="0 0 66.000000 66.000000"
    //       preserveAspectRatio="none">

    //       <g transform="translate(0.000000,66.000000) scale(0.100000,-0.100000)"
    //         fill="#000000" stroke="none">
    //         <path d="M67 453 c-4 -3 -7 -64 -7 -135 l0 -128 265 0 265 0 0 135 0 135 -258 0 c-142 0 -262 -3 -265 -7z m501 -131 l3 -112 -243 2 -243 3 -3 99 c-2 64 1 103 9 112 10 12 53 14 243 12 l231 -3 3 -113z" />
    //         <path d="M256 329 c-17 -21 -35 -39 -40 -39 -6 0 -25 17 -43 37 -17 21 -33 36 -36 33 -3 -3 13 -27 37 -54 l42 -48 38 46 c20 25 36 50 34 55 -2 5 -16 -8 -32 30z" />
    //       </g>
    //     </svg>)
    //   case "date":
    //     //
    //     return (<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    //       width="24px" height="24px" viewBox="0 0 66.000000 66.000000"
    //       preserveAspectRatio="none">

    //       <g transform="translate(0.000000,66.000000) scale(0.100000,-0.100000)"
    //         fill="#000000" stroke="none">
    //         <path d="M274 443 c-3 -13 -14 -23 -23 -23 -9 0 -22 -5 -29 -12 -16 -16 -16 -190 0 -206 7 -7 42 -12 88 -12 75 0 77 1 113 38 35 36 37 41 37 103 0 67 -10 89 -41 89 -9 0 -20 10 -23 23 l-7 22 -11 -22 c-8 -17 -20 -23 -43 -23 -23 0 -35 6 -43 23 l-11 22 -7 -22z m166 -122 c0 -37 -2 -40 -32 -43 -29 -3 -33 -7 -36 -35 l-3 -33 -70 0 -69 0 0 75 0 75 105 0 105 0 0 -39z" />
    //         <path d="M260 309 c0 -14 5 -19 17 -17 26 5 29 38 4 38 -15 0 -21 -6 -21 -21z" />
    //         <path d="M312 313 c2 -10 12 -18 23 -18 11 0 21 8 23 18 3 13 -3 17 -23 17 -20 0 -26 -4 -23 -17z" />
    //         <path d="M372 313 c5 -26 38 -29 38 -4 0 15 -6 21 -21 21 -14 0 -19 -5 -17 -17z" />
    //         <path d="M260 260 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20 -13 0 -20 -7 -20 -20z" />
    //         <path d="M315 270 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43 7z" />
    //       </g>
    //     </svg>)
    //   case "booleancheckbox":
    //     return (<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    //       width="24px" height="24px" viewBox="0 0 66.000000 66.000000"
    //       preserveAspectRatio="none">

    //       <g transform="translate(0.000000,66.000000) scale(0.100000,-0.100000)"
    //         fill="#000000" stroke="none">
    //         <path d="M117 423 c-4 -3 -7 -46 -7 -95 l0 -88 95 0 95 0 0 95 0 95 -88 0 c-49 0 -92 -3 -95 -7z m173 -93 l0 -80 -80 0 -80 0 0 80 0 80 80 0 80 0 0 -80z" />
    //         <path d="M213 348 c-17 -17 -25 -19 -36 -10 -21 18 -28 3 -10 -25 l16 -24 33 32 c33 32 42 49 26 49 -4 0 -17 -10 -29 -22z" />
    //         <path d="M357 424 c-4 -4 -7 -47 -7 -96 l0 -88 95 0 96 0 -3 93 -3 92 -85 3 c-47 1 -89 0 -93 -4z m171 -91 l-3 -78 -77 -3 -78 -3 0 81 0 80 80 0 81 0 -3 -77z" />
    //         <path d="M453 348 c-18 -17 -25 -19 -37 -9 -22 19 -31 -6 -10 -30 17 -19 18 -19 51 13 32 31 41 48 25 48 -4 0 -17 -10 -29 -22z" />
    //       </g>
    //     </svg>
    //     )
    //   case "textarea":
    //     // 
    //     return (<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    //       width="24px" height="24px" viewBox="0 0 66.000000 66.000000"
    //       preserveAspectRatio="none">


    //       <g transform="translate(0.000000,66.000000) scale(0.100000,-0.100000)"
    //         fill="#000000" stroke="none">
    //         <path d="M150 335 l0 -125 185 0 185 0 0 125 0 125 -185 0 -185 0 0 -125z m350 0 l0 -105 -165 0 -165 0 0 105 0 105 165 0 165 0 0 -105z" />
    //         <path d="M210 393 c5 -2 8 -8 6 -12 -3 -4 0 -13 5 -21 8 -12 9 -9 7 9 -2 14 -9 26 -16 27 -7 1 -8 0 -2 -3z" />
    //         <path d="M269 391 c9 -6 11 -16 7 -27 -6 -15 -4 -16 5 -7 14 14 5 43 -13 43 -10 0 -9 -3 1 -9z" />
    //         <path d="M303 384 c-3 -8 0 -21 6 -27 9 -9 11 -8 5 7 -4 11 -2 21 7 27 10 6 11 9 1 9 -7 0 -15 -7 -19 -16z" />
    //         <path d="M210 303 c5 -2 8 -8 6 -12 -3 -4 0 -13 5 -21 8 -12 9 -9 7 9 -2 14 -9 26 -16 27 -7 1 -8 0 -2 -3z" />
    //         <path d="M269 301 c9 -6 11 -16 7 -27 -6 -15 -4 -16 5 -7 14 14 5 43 -13 43 -10 0 -9 -3 1 -9z" />
    //         <path d="M303 294 c-3 -8 0 -21 6 -27 9 -9 11 -8 5 7 -4 11 -2 21 7 27 10 6 11 9 1 9 -7 0 -15 -7 -19 -16z" />
    //       </g>
    //     </svg>)
    //   // case "custom input":
    //   //   return (<input onKeyDown={(e) => onInputCustom(e)} onChange={(e) => onChangeCustom(e)} defaultValue={components[data.id].data.data} placeholder={components[data.id].data.placeholder} className="h-10 px-3 bg-white border border-gray-300 rounded w-full"></input>)
    //   default:
    //     // 
    return (<svg xmlns="http://www.w3.org/2000/svg" width="5px" height="24px">
      <path fill="currentColor" d="M0 0h2v2H0V0zm0 8h2v2H0V8zm0 8h2v2H0v-2zM0 4h2v2H0V4zm0 8h2v2H0v-2zm0 8h2v2H0v-2zM3 0h2v2H3V0zm0 8h2v2H3V8zm0 8h2v2H3v-2zM3 4h2v2H3V4zm0 8h2v2H3v-2zm0 8h2v2H3v-2z" />
    </svg>)
    // }
  }

  return (<span className="cursor-grab w-full overflow-hidden h-8 flex rounded-md items-center pl-1 pr-2 text-sm font-medium bg-slate-100 text-slate-700 mb-1" ref={drag}
    style={{ opacity }}>
    <div className="text-slate-400">
      {renderSwitch(data.component.data.fieldType)}
    </div>
    <TooltipWhenTooLong><div className="px-2">{data.component.data.label}</div></TooltipWhenTooLong>
  </span>
  );
};
export default SideBarItem;